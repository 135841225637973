// Cards list - .card-list-default

.card-list-default {
    .card-list-item {
        background-color: var(--module-container-bg-color);
        box-shadow: var(--module-container-shadow);
        border-radius: $border-radius-10;
        padding: 0.6rem 0rem;

        &:before {
            content:'';
            width: 5px;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--module-container-active-before-color);
        }

        > * {
            padding: 0.6rem 2.4rem;
        }

        .date {
            text-align: center;
            line-height: 1.2;
            position: relative;
            color: var(--card-list-text-color); 
            border-width: 0 1px;
            border-style: solid;
            border-color: var(--module-container-divider);
    
            &:first-child { border-width: 0 1px 0 0; }

            &:last-child { border-width: 0 0 0 1px; }
          
            
            > *:not(span) {
                color: var(--card-list-text-color);
            }

            span {
                font-size: $font-size-xl;
                display: block;
                color: var(--body-font-color);
            }

        }

        .divider {
            border-color: var(--module-container-divider);
        }
    }
}