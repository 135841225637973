
// Toggle/Switch button
.switch {
    display: inline-block;
    position: relative;
    content: "";
    min-height: 20px;
    margin: 0 12px 6px 0;
    padding: 1px 0;

    color: var(--switches-text-color);
    font-size: $font-size-sm;
    line-height: 1.8rem;

    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // hide the input, keep for functionality
    input {
        &[type="checkbox"],
        &[type="radio"] {
            position: absolute;
            opacity: 0;
            cursor: default;
            width: 0;
            height: 0;
        }

        &:focus {
            ~ .togglemark {
                &::before {
                    box-shadow: 0 0 0 1px var(--switches-focus-border-color), inset 0 0 0 1px var(--switches-focus-border-color);
                }
            }
        }

        &:checked, &.checked {
            ~ .togglemark {
                &::before {
                    color: var(--switches-checked-text-color);
                    text-align: left;

                    background-color: var(--switches-checked-bg);
                    box-shadow: none;
                }

                &::after {
                    left: 15px
                }

                &:hover {
                    &::before {
                        background-color: var(--switches-checked-hover-bg);
                        box-shadow: none;
                    }
                }
            }

            &:focus {
                ~ .togglemark {
                    &::before {
                        box-shadow: 0 0 0 1px var(--switches-focus-border-color), inset 0 0 0 1px var(--switches-focus-border-color), inset 0 0 0 2px var(--switches-focus-inner-border-color);
                    }
                }
            }
        }
    }

    // define styles for the visual que of the switch/toggle button
    .togglemark {
        position: relative;
        cursor: pointer;
        padding-left: 40px;

        &::before, &::after {
            position: absolute;
            display: block;
            content: '';
        }

        &::before {
            width: 28px;
            height: 16px;
            top: 2px;
            left: 1px;

            color: var(--switches-text-color);
            font: {
                size: $font-xs;
                weight: $bold;
            }
            text-align: right;

            border-radius: 16px;
            background-color: var(--switches-bg);
            box-shadow: inset 0 0 0 1px var(--switches-border-color);

            transition: background-color 0.12s ease-in-out;
        }

        &::after {
            width: 12px;
            height: 12px;
            top: 4px;
            left: 3px;

            border-radius: 12px;
            background-color: var(--switches-togglemark-bg);
            box-shadow: $shadow-20 var(--switches-togglemark-shadow-color);

            transition: left 0.09s ease-in-out;
        }

        &:hover {
            box-shadow: none;

            &::before {
                background-color: var(--switches-hover-bg);
            }
        }
    }

    // define the styles for the large/biger version of the switch/toggle button
    &.switch-lg {
        line-height: 24px;

        .togglemark {
            padding-left: 60px;

            &::before {
                width: 44px;
                height: 24px;
                top: -3px;

                border-radius: 24px;
            }

            &::after {
                width: 18px;
                height: 18px;
                top: 0;
                left: 4px;

                border-radius: 18px;
            }
        }

        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::after {
                        left: 24px;
                    }
                }
            }
        }
    }

    // define the styles for the version with "ON"/"OFF" text
    &.switch-label {
        line-height: 24px;

        .togglemark {
            padding-left: 75px;

            &::before {
                box-sizing: border-box;
                content: 'OFF';
                width: 62px;
                height: 24px;
                top: -3px;
                padding: 0 12px;

                line-height: 24px;
                text-indent: 0;

                border-radius: 24px;
            }

            &::after {
                width: 18px;
                height: 18px;
                top: 0;
                left: 4px;

                border-radius: 18px;
            }
        }

        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::before {
                        content: 'ON';
                    }

                    &::after {
                        left: 42px;
                    }
                }
            }
        }
    }
}

// Disabled style of the switch/toggle button
.disabled .switch input, :disabled .switch input,
.switch.disabled input, .switch:disabled input,
.switch input.disabled,
.switch input:disabled {
    &[type="checkbox"],
    &[type="radio"] {
        ~ .togglemark {
            cursor: default;
            pointer-events: none;
            color: var(--input-disabled-text-color);

            &::before {
                color: var(--switches-disabled-text-color);

                background-color: var(--switches-disabled-bg);
                box-shadow: none;
            }

            &::after {
                background-color: var(--switches-disabled-togglemark-bg);
                box-shadow: none;
            }
        }
    }
}


// Switcher from light to dark mode
.light-dark-toggle {
    &.switch { 
        &.switch-label {
            margin: 0;
            padding: 15px 5px 15px 25px;
            width: 100%;
            display: inline-flex;
            align-items: center;

            b { 
                font-size: 14px; 
                // min-width: 110px;

                // &:first-child { text-align: right; }
                // &:last-child { text-align: left; }
            }

            .togglemark {
                margin: 0 15px;
                overflow: hidden;
                width: 48px;
                min-width: 48px;
                height: 24px;
                padding: 0;
                display: inline-flex;
                border-radius: 12px;
                
                &, &:hover {
                    background-image: linear-gradient(360deg, #52B9FF 0%, #1C98EB 100%);
                    transition: background-image 0.3s ease-in-out;

                    &::before,
                    &::after {
                        content: '';  
                        width: 16px !important;
                        height: 16px !important;
                        top: 4px;
                    }

                    &::before {
                        width: 50px;
                        padding: 0 6px 0 6px;
                        background: radial-gradient(62.5% 62.5% at 37.5% 37.5%, #FED177 0%, #FEC044 100%);
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                        left: 4px;
                        transition: left 0.3s ease-in-out;
                    }

                    &::after {
                        background: linear-gradient(to bottom, #1B3D54 0%, #224157 100%);
                        box-shadow: none;
                        top: 2px;
                        left: 2px;
                        transition: left 0.3s ease-in-out, opacity 0.02s ease-in-out;
                        opacity: 0;
                    }

                    .details {
                        &:before, 
                        &:after { 
                            content:''; 
                            display: block;
                            position: absolute;
                            z-index: 1;
                            top: 3px;
                        }

                        &:before { //Clouds
                            background-image: url(#{$image-path}/switcher-clouds.svg); 
                            width: 48px;
                            height: 24px; 
                            left: 4px;
                            top: 0px;
                            opacity: 1;
                            transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
                        }

                        &:after { //Stars
                            background-image: url(#{$image-path}/switcher-stars.svg); 
                            width: 22px;
                            height: 8px;
                            left: 8px;
                            opacity: 0;
                            transition: opacity 0.3s ease-in-out;
                        }
                    }
                }
            }
    
            input {
                &:checked, &.checked {
                    ~ .togglemark {
                        background-image: linear-gradient(to bottom, #193C54 0%, #244358 100%);
                        transition: background-image 0.3s ease-in-out;

                        &::before,
                        &::after { content:''; }

                        &::before {
                            left: 26px;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                            transition: left 0.3s ease-in-out;
                        }

                        &::after {
                            left: 22px;
                            opacity: 1;
                            transition: left 0.3s ease-in-out, opacity 0.02s ease-in-out;
                        }

                        .details {
                            &:before { 
                                left: -23px;
                                opacity: 0.4;
                                transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
                            }

                            &:after { 
                                opacity: 1;
                                transition: opacity 0.3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }

    b.light {
        @media (prefers-color-scheme: dark) { font-weight: 400; }
        @media (prefers-color-scheme: light) { font-weight: 700; }
        .dark-theme & { font-weight: 400; }
        .light-theme & { font-weight: 700; }
    }

    b.dark {
        @media (prefers-color-scheme: light) { font-weight: 400; }
        @media (prefers-color-scheme: dark) { font-weight: 700; }
        .light-theme & { font-weight: 400; }
        .dark-theme & { font-weight: 700; }
    }
} // Switch from dark-light


