// Cards list - .card-list-primary

.card-list-primary {
    .card-list-item {
        background-color: var(--module-container-bg-color);
        box-shadow: var(--module-container-shadow);
        border-radius: $border-radius-5;

        // @media (max-width: $screen-md-min) { justify-content: end; }
    
        // > * {
        //     padding: 0.8rem 2.8rem;
        // }

        .title {
            display: flex;
            align-items: center;

            .vismaicon { margin-right: 2.4rem; }

            h4 { 
                font-size: 2rem; 
                // @media (max-width: $screen-lg-min) { padding-top: 1.6rem; }
            }

            .date { color: var(--card-list-text-color); }
        }

        .description {
            flex-direction: column;
            align-items: initial;
            color: var(--card-list-text-color);

            @media (max-width: $screen-md-min) { 
                padding-left: 7.8rem; 
                margin-bottom: 20px;
            }

            p {
                margin: 0;
            }
        }

        .icon,
        .action {
            text-align: center;

            p { margin: 0; }

            .vismaicon { margin: 0 .6rem; }
        }

        .divider {
            border-color: var(--module-container-divider);
        }

        .price {
            font-size: 2rem;
            color: var(--card-list-text-color);
            text-align: right;
            min-width: 12.6rem;

            @media (max-width: $screen-md-min) { 
                border-top: 1px solid var(--module-container-divider);
                margin-bottom: -1.6rem;
            }

            b, strong {
                color: var(--body-font-color);
            }
        }
    }
}