
// NC4 variables
//
//=== Table of Contents:
//
//  1. Core CSS Variables
//    # a. Color palette
//    # b. Icons
//    # c. Typography
//
//  2. Light mode CSS Variables (mixin)
//    # a. Color palette - Neutral palette
//    # b. Base body properties
//    # c. Typography
//    # d. Buttons
//    # e. Icons
//    # f. Badges
//    # g. List groups
//    # h. Navigation
//    # i. Vertical navigation
//    # j. Modals
//    # k. Forms
//         - inputs
//         - switches
//         - radio-checkbox
//    # l. Images
//    # m. Table
//    # n. Stepper
//    # o.
//
//  3. Dark mode CSS Variables (mixin)
//    # a. Color palette - Neutral palette
//    # b. Base body properties
//    # c. Typography
//    # d. Buttons
//    # e. Icons
//    # f. Badges
//    # g. List groups
//    # h. Navigation
//    # i. Vertical navigation
//    # j. Modals
//    # k. Forms
//         - inputs
//         - switches
//         - radio-checkbox
//    # l. Images
//    # m. Table
//    # n. Stepper
//    # o. Progress Bar


//=== Aditional SCSS Variables
@import '../variables';
//== Your custom scss overwrite variables
@import '../custom-variables';


/* Setup a few global scope properties */
:root {
    //=== 1. Core CSS Variables
    //# a. Color palette
    --white: #{$white};
    --black: #{$black};


    //### Blue palette
    --blue-90: #{$blue-90};
    --blue-80: #{$blue-80};
    --blue-70: #{$blue-70};
    --blue-60: #{$blue-60}; 
    --blue-50: #{$blue-50};
    --blue-40: #{$blue-40};
    --blue-30: #{$blue-30};
    --blue-20: #{$blue-20};
    --blue-10: #{$blue-10};
    --blue-05: #{$blue-05};

    --primary-blue: #{$primary-blue};


    //### Green palette
    --green-90: #{$green-90};
    --green-80: #{$green-80};
    --green-70: #{$green-70};
    --green-60: #{$green-60};
    --green-50: #{$green-50};
    --green-40: #{$green-40};
    --green-30: #{$green-30};
    --green-20: #{$green-20};
    --green-10: #{$green-10};
    --green-05: #{$green-05};
    
    --primary-green: #{$primary-green};


    //### Red palette
    --red-90: #{$red-90};
    --red-80: #{$red-80};
    --red-70: #{$red-70};
    --red-60: #{$red-60};
    --red-50: #{$red-50};
    --red-40: #{$red-40};
    --red-30: #{$red-30};
    --red-20: #{$red-20};
    --red-10: #{$red-10};
    --red-05: #{$red-05};
    
    --primary-red: #{$primary-red};


    //### Orange palette
    --orange-90: #{$orange-90};
    --orange-80: #{$orange-80};
    --orange-70: #{$orange-70};
    --orange-60: #{$orange-60};
    --orange-50: #{$orange-50};
    --orange-40: #{$orange-40};
    --orange-30: #{$orange-30};
    --orange-20: #{$orange-20};
    --orange-10: #{$orange-10};
    --orange-05: #{$orange-05};
    
    --primary-orange: #{$primary-orange};


    //# b. Icons
  --stepper-visited-icon-light: #{$check-mark-light};
  --stepper-visited-icon-dark: #{$check-mark-dark}; 


  //# c. Typography
  // base/html font size
  --html-font-size: #{$html-font-size};
  --body-font-size: #{$body-font-size};
}


// Light and Dark CSS Variables
//
// 2. Light mode CSS Variables
@mixin light-mode {
  //=== a. Color palette
  //# Neutral palette
  --neutral-90: #{$neutral-light-90};
  --neutral-80: #{$neutral-light-80};
  --neutral-70: #{$neutral-light-70};
  --neutral-60: #{$neutral-light-60};
  --neutral-50: #{$neutral-light-50};
  --neutral-40: #{$neutral-light-40};
  --neutral-30: #{$neutral-light-30};
  --neutral-20: #{$neutral-light-20};
  --neutral-10: #{$neutral-light-10};
  --neutral-05: #{$neutral-light-05};

  --primary-neutral: #{$primary-neutral-light};


  //=== b. Base body properties
  // Set site background color
  --root-background-color: var(--custom-root-background-color, var(--neutral-20));
  --scrollbar-track-bg: var(--root-background-color);
  --scrollbar-thumb-bg: rgba(#{hex-to-rgb($blue-90)}, 0.25);

  // body properties
  --body-font-color: var(--custom-body-font-color, var(--primary-neutral));
  --titles-font-color: var(--custom-titles-font-color, var(--primary-neutral));

  // Components background color
  --module-container-bg-color: var(--custom-module-container-bg-color, var(--neutral-05));
  --module-container-shadow: var(--custom-module-container-shadow, #{shadow(10)} rgba(#{hex-to-rgb($blue-90)}, 0.1));
  --module-container-divider: var(--custom-module-container-divider, var(--neutral-20));
  --module-container-active-bg-color: var(--custom-module-container-active-bg-color, var(--neutral-20));
  --module-container-active-before-color: var(--custom-module-container-active-before-color, var(--green-60));
  --module-container-hover-bg-color: var(--custom-module-container-hover-bg-color, var(--blue-10));


  //=== c. Typography
  //body text
  --text-default: var(--custom-text-default, var(--primary-neutral));
  --text-primary: var(--custom-text-primary, var(--blue-70));
  --text-disabled: var(--custom-text-disabled, var(--neutral-60)); 
  --text-error: var(--custom-text-error, var(--red-80)); 
  --text-warning: var(--custom-text-warning, var(--orange-90));  

  // anchor/breadcrumbs properties
  --anchor-color: var(--custom-anchor-color, var(--blue-70));
  --anchor-hover-color: var(--custom-anchor-hover-color, var(--blue-70));
  --anchor-focus-outline: var(--custom-anchor-focus-color, var(--blue-50));
  --anchor-disabled-color: var(--custom-anchor-disabled-color, var(--neutral-60));
  // anchor/breadcrumbs - active/selected state
  --anchor-selected-color: var(--custom-anchor-selected-color, var(--primary-neutral));

  // 'mark' tag/element (highlighted text)
  --highlighted-text-bg-color: var(--custom-highlighted-text-bg-color, var(--orange-20));
  --highlighted-text-color: var(--custom-highlighted-text-color, var(--primary-neutral));

  // 'hr' tag/element
  --divider-color: var(--custom-divider-color, var(--neutral-40));
  --divider-default-color: var(--custom-divider-default-color, var(--neutral-40));
  --divider-primary-color: var(--custom-divider-primary-color, var(--primary-blue));
  --divider-disabled-color: var(--custom-divider-disabled-color, var(--neutral-10));
  --divider-error-color: var(--custom-divider-error-color, var(--primary-red));


  //=== d. Buttons
  //## Default button
  --button-text-color: var(--custom-button-text-color, var(--primary-neutral));
  --button-border-color: var(--custom-button-border-color, var(--neutral-50));
  --button-inner-border-color: var(--custom-button-inner-border-color, var(--neutral-40));
  --button-bg: var(--custom-button-bg, var(--neutral-05));
  --button-shadow-color: var(--custom-button-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.12));
  --button-icon: var(--custom-button-icon, var(--blue-50));

  // Default button - hover state
  --button-hover-border-color: var(--custom-button-hover-border-color, var(--blue-20));
  --button-hover-bg: var(--custom-button-hover-bg, var(--blue-10));
  --button-hover-shadow-color: var(--custom-button-hover-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.15));
  
  // Default button - focus state
  --button-focus-border-color: var(--custom-button-focus-border-color, var(--blue-50));
  --button-focus-shadow-color: var(--custom-button-focus-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.15));

  // Default button - active/pressed state
  --button-active-border-color: var(--custom-button-active-border-color, var(--neutral-50));
  --button-active-bg: var(--custom-button-active-bg, var(--neutral-40));

  // Default button - active/selected state
  --button-selected-bg: var(--custom-button-selected-bg, var(--neutral-20));

  // Default button - disabled state
  --button-disabled-text-color: var(--custom-button-disabled-text-color, var(--neutral-60));
  --button-disabled-border-color: var(--custom-button-disabled-border-color, var(--neutral-40));
  --button-disabled-inner-border-color: var(--custom-button-disabled-inner-border-color, var(--neutral-50));
  --button-disabled-bg: var(--custom-button-disabled-bg, var(--neutral-05));

  //## Primary button
  --button-primary-text-color: var(--custom-button-primary-text-color, var(--neutral-05));
  --button-primary-border-color: var(--custom-button-primary-border-color, var(--primary-green));
  --button-primary-inner-border-color: var(--custom-button-primary-inner-border-color, var(--green-80));
  --button-primary-bg: var(--custom-button-primary-bg, var(--primary-green));
  --button-primary-shadow-color: var(--custom-button-primary-shadow-color, rgba(#{hex-to-rgb($green-80)}, 0.20));
  --button-primary-icon: var(--custom-button-primary-icon, var(--neutral-05));

  // Primary button - hover state
  --button-primary-hover-border-color: var(--custom-button-primary-hover-border-color, var(--green-60));
  --button-primary-hover-bg: var(--custom-button-primary-hover-bg, var(--green-60));

  // Primary button - focus state
  --button-primary-focus-border-color: var(--custom-button-primary-focus-border-color, var(--blue-50));
  --button-primary-focus-inner-shadow-color: var(--custom-button-primary-focus-inner-shadow-color, var(--neutral-05));
  --button-primary-focus-shadow-color: var(--custom-button-primary-focus-shadow-color, rgba(#{hex-to-rgb($green-80)}, 0.20));

  // Primary button - active/pressed state
  --button-primary-active-border-color: var(--custom-button-primary-active-border-color, var(--green-80));
  --button-primary-active-bg: var(--custom-button-primary-active-bg, var(--green-80));

  // Primary button - active/selected state
  --button-primary-selected-bg: var(--custom-button-primary-selected-bg, var(--primary-green));


  //# dropdown button
  --dropdown-menu-text-color: var(--custom-dropdown-menu-text-color, var(--primary-neutral));
  --dropdown-menu-bg: var(--custom-dropdown-menu-bg, var(--neutral-05));
  --dropdown-menu-shadow-color: var(--custom-dropdown-menu-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.15));

  // dropdown button - hover state
  --dropdown-menu-hover-bg: var(--custom-dropdown-menu-hover-bg, var(--blue-10));

  // dropdown button - active/selected state
  --dropdown-menu-selected-text-color: var(--custom-dropdown-menu-selected-text-color, var(--primary-blue));

  // dropdown arrow
  --dropdown-arrow-bg: var(--custom-dropdown-arrow-bg, var(--primary-neutral));
  --dropdown-arrow-disabled-bg: var(--custom-dropdown-arrow-disabled-bg, var(--neutral-70));

  --dropdown-primary-arrow-bg: var(--custom-dropdown-primary-arrow-bg, var(--neutral-05));



  // wizard selected
  --wizard-selected-border-color: var(--custom-wizard-selected-border-color, var(--green-60));
  --wizard-checkmark-icon: var(--custom-wizard-checkmark-icon, url(#{$image-path}/wizard-checkmark.svg)); 

  // wizard tabdrop
  --wizard-tabdrop-color: var(--custom-wizard-tabdrop-color, var(--primary-neutral));


  //=== e. Icons
  // worksourface, action and social media icons
  --icon-bg: var(--custom-icon-bg, var(--blue-50));
  --icon-hover-bg: var(--custom-icon-hover-bg, var(--blue-40));
  --icon-disabled-bg: var(--custom-icon-disabled-bg, var(--neutral-60));

  // Informative icons
  --icon-informative-success-bg: var(--custom-icon-informative-success-bg, var(--green-60));
  --icon-informative-error-bg: var(--custom-icon-informative-error-bg, var(--red-60));
  --icon-informative-warning-bg: var(--custom-icon-informative-warning-bg, var(--orange-70));
  --icon-informative-info-bg: var(--custom-icon-informative-info-bg, var(--blue-50));
  --icon-informative-help-bg: var(--custom-icon-informative-help-bg, var(--blue-50));

  // Carets and close icons
  --caret-default-bg: var(--custom-caret-default-bg, var(--neutral-90));
  --caret-primary-bg: var(--custom-caret-primary-bg, var(--blue-50));
  --caret-secondary-bg: var(--custom-caret-secondary-bg, var(--neutral-05));
  --caret-disabled-bg: var(--custom-caret-disabled-bg, var(--neutral-70));
  --caret-navigation-bg: var(--custom-caret-navigation-bg, var(--neutral-05));
  --caret-open-navigation-bg: var(--custom-caret-open-navigation-bg, var(--blue-50));

  
  //=== f. Badges
  --badge-bg: var(--custom-badge-bg, var(--neutral-05));
  --badge-text-color: var(--custom-badge-text-color, var(--primary-neutral));
  --badge-shadow: var(--custom-badge-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.25));

  --badge-success-bg: var(--custom-badge-success-bg, var(--primary-green ));

  --badge-warning-bg: var(--custom-badge-warning-bg, var(--primary-orange));

  --badge-danger-bg: var(--custom-badge-danger-bg, var(--primary-red));
  --badge-danger-shadow: var(--custom-badge-danger-shadow, #{0 3px 6px} rgba(#{hex-to-rgb($red-80)}, 0.2));
  
  --badge-info-bg: var(--custom-badge-info-bg, var(--primary-blue));


  //=== g. List groups
  --list-group-divider: var(--custom-list-group-divider, #{inset 0 1px 0 0} rgba(#{hex-to-rgb($neutral-20)}));
  --list-group-header-bg: var(--custom-list-group-header-bg, var(--primary-green));
  --list-group-badge-bg: var(--custom-list-group-badge-bg, rgba(#{hex-to-rgb($neutral-20)}, 0));
  --list-group-badge-border: var(--custom-list-group-badge-border, rgba(#{hex-to-rgb($neutral-light-30)}));
  --list-group-badge-text: var(--custom-list-group-badge-text, var(--neutral-80));

  --list-group-primary-bg: var(--custom-list-group-primary-bg, var(--primary-blue));
  --list-group-primary-text-color: var(--custom-list-group-primary-text-color, var(--neutral-05));
  --list-group-primary-divider: var(--custom-list-group-primary-divider, #{inset 0 1px 0 0} rgba(#{hex-to-rgb($blue-50)}));
  --list-group-primary-active-bg: var(--custom-list-group-primary-active-bg, var(--blue-70));
  --list-group-primary-hover-bg: var(--custom-list-group-primary-hover-bg, rgba(#{hex-to-rgb($neutral-05)}, 0.1));



  //=== h. Navigation
  --navigation-default-bg: var(--custom-navigation-default-bg, linear-gradient(to right,rgba(#{hex-to-rgb($blue-80)}) 0%, rgba(#{hex-to-rgb($blue-60)}) 100%));
  --navigation-default-shadow: var(--custom-navigation-default-shadow, #{0 0px 10px} rgba(#{hex-to-rgb($blue-70)}, 0.25)); 
  --navigation-default-divider-bg: var(--custom-navigation-default-divider-bg, rgba(#{hex-to-rgb($neutral-05)}, 0.1));

  --navigation-default-second-level-bg: var(--custom-navigation-default-second-level-bg, var(--neutral-05));
  --navigation-default-second-level-shadow: var(--custom-navigation-default-second-level-shadow, #{shadow(20)} rgba(#{hex-to-rgb($blue-90)}, 0.08));
  --navigation-default-second-level-divider: var(--custom-navigation-default-second-level-divider, var(--neutral-05));

  --navigation-default-item-text-color: var(--custom-navigation-default-item-text-color, var(--neutral-05));
  --navigation-default-item-hover-bg: var(--custom-navigation-default-item-hover-bg, rgba(#{hex-to-rgb($neutral-05)}, 0.1));
  --navigation-default-item-focus-border-color: var(--custom-navigation-default-item-hover-bg, var(--neutral-05));
  --navigation-default-item-active-bg: var(--custom-navigation-default-item-active-bg, rgba(#{hex-to-rgb($blue-90)}, 0.5));

  --navigation-default-icon-bg: var(--custom-navigation-default-icon-bg, var(--neutral-05));
  --navigation-default-dropdown-open-icon-bg: var(--custom-navigation-default-dropdown-open-icon-bg, var(--blue-50));

  --navigation-default-item-dropdown-open-bg: var(--custom-navigation-default-item-dropdown-open-bg, var(--neutral-05));
  --navigation-default-item-dropdown-open-text-color: var(--custom-navigation-default-item-dropdown-open-text-color, var(--primary-neutral));
  --navigation-default-item-dropdown-small-text-color: var(--custom-navigation-default-item-dropdown-small-text-color, var(--neutral-05));
  --navigation-default-item-dropdown-open-small-text-color: var(--custom-navigation-default-item-dropdown-open-small-text-color, var(--neutral-80));

  --navigation-default-dropdown-shadow: var(--custom-navigation-default-dropdown-shadow, #{0 12px 15px -3px} rgba(#{hex-to-rgb($blue-90)}, 0.15));
  --navigation-default-dropdown-text-color: var(--custom-navigation-default-dropdown-text-color, var(--primary-neutral));
  --navigation-default-dropdown-divider-color-dotted: var(--custom-navigation-default-dropdown-divider-color-dotted, var(--neutral-50));
  --navigation-default-dropdown-divider-color-strong: var(--custom-navigation-default-dropdown-divider-color-strong, var(--neutral-20));

  --navigation-default-attention-bg: var(--custom-navigation-default-attention-bg, var(--orange-60));
  --navigation-default-attention-shadow: var(--custom-navigation-default-attention-shadow, #{shadow(20)} rgba(#{hex-to-rgb($orange-80)}, 0.2));
  --navigation-default-badge-bg: var(--custom-navigation-default-badge-bg, var(--badge-bg));
  --navigation-default-badge-text-color: var(--custom-navigation-default-badge-text-color, var(--primary-neutral));

  --navigation-default-menudrop-bg: var(--custom-navigation-default-menudrop-bg, var(--neutral-05));
  --navigation-default-menudrop-shadow: var(--custom-navigation-default-menudrop-shadow, #{shadow(40)} rgba(#{hex-to-rgb($blue-90)}, 0.15));

  --navigation-default-hamburger-bg: var(--custom-navigation-default-hamburger-bg, var(--neutral-05));
  --navigation-default-hamburger-open-bg: var(--custom-navigation-default-hamburger-bg, var(--neutral-80));

  // Secondary navigation
  --navigation-secondary-bg: var(--custom-navigation-secondary-bg, var(--neutral-05));
  --navigation-secondary-shadow: var(--custom-navigation-secondary-bg, #{0 5px 10px} rgba(#{hex-to-rgb($blue-90)}, 0.08)); 
  --navigation-secondary-divider-bg: var(--custom-navigation-secondary-divider-bg, var(--neutral-20));

  --navigation-secondary-second-level-shadow: var(--custom-navigation-secondary-second-level-shadow, #{shadow(20)} rgba(#{hex-to-rgb($blue-90)}, 0.08));
  --navigation-secondary-second-level-divider: var(--custom-navigation-secondary-second-level-divider, var(--navigation-secondary-divider-bg));

  --navigation-secondary-item-text-color: var(--custom-navigation-secondary-item-text-color, var(--primary-neutral));
  --navigation-secondary-item-active-text-color: var(--custom-navigation-secondary-item-active-text-color, var(--primary-blue));
  --navigation-secondary-item-active-font-weight: var(--custom-navigation-secondary-item-active-font-weight, #{bold});
  --navigation-secondary-item-active-shadow: var(--custom-navigation-secondary-item-active-shadow, #{inset 0 -4px 0px} rgba(#{hex-to-rgb($blue-50)})); 
  --navigation-secondary-item-active-bg: var(--custom-navigation-secondary-item-active-bg, var(--neutral-05));
  --navigation-secondary-item-hover-bg: var(--custom-navigation-secondary-item-hover-bg, var(--blue-10));

  --navigation-secondary-item-dropdown-small-text-color: var(--custom-navigation-secondary-item-dropdown-small-text-color, var(--neutral-80));

  --navigation-secondary-icon-bg: var(--custom-navigation-secondary-icon-bg, var(--blue-50));

  --navigation-secondary-menudrop-icon-bg: var(--custom-navigation-secondary-menudrop-icon-bg, var(--neutral-80));

  --navigation-secondary-hamburger-bg: var(--custom-navigation-secondary-hamburger-bg, var(--neutral-80));
  --navigation-secondary-hamburger-open-bg: var(--custom-navigation-secondary-hamburger-bg, var(--neutral-80));



  //=== i. Vertical navigation 
  --vertical-nav-default-shadow: var(--custom-vertical-nav-default-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.08)); 
  --vertical-nav-default-item-active-bg: var(--custom-vertical-nav-default-item-active-bg, rgba(#{hex-to-rgb($blue-90)}, 0.1));
  --vertical-nav-default-collapse-btn-bg: var(--custom-vertical-nav-default-collapse-btn-bg, var(--blue-50));
  --vertical-nav-default-collapse-btn-hover-bg: var(--custom-vertical-nav-default-collapse-btn-hover-bg, var(--blue-40));
  --vertical-nav-default-collapse-btn-shadow: var(--custom-vertical-nav-default-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-50)}, 0.3)); 

  --vertical-nav-secondary-bg: var(--custom-vertical-nav-secondary-bg, var(--blue-80));
  --vertical-nav-secondary-shadow: var(--custom-vertical-nav-secondary-shadow, #{0 0 10px} rgba(#{hex-to-rgb($blue-90)}, 0.1)); 
  --vertical-nav-secondary-item-text: var(--custom-vertical-nav-secondary-item-text, var(--neutral-05));
  --vertical-nav-secondary-item-hover-bg: var(--custom-vertical-nav-secondary-item-hover-bg, rgba(#{hex-to-rgb($neutral-05)}, 0.1));
  --vertical-nav-secondary-item-active-bg: var(--custom-vertical-nav-secondary-item-active-bg, var(--blue-90));
  --vertical-nav-secondary-item-disabled: var(--custom-vertical-nav-secondary-item-disabled, rgba(#{hex-to-rgb($neutral-05)}, 0.5));
  --vertical-nav-secondary-collapse-bg: var(--custom-vertical-nav-secondary-collapse-bg, rgba(#{hex-to-rgb($blue-90)}, 0.5));
  --vertical-nav-secondary-collapse-btn-bg: var(--custom-vertical-nav-secondary-collapse-btn-bg, var(--neutral-05));
  --vertical-nav-secondary-collapse-btn-hover-bg: var(--custom-vertical-nav-secondary-collapse-btn-hover-bg, var(--neutral-05));
  --vertical-nav-secondary-collapse-arrow: var(--custom-vertical-nav-secondary-collapse-arrow, var(--neutral-90));
  --vertical-nav-secondary-collapse-btn-shadow: var(--custom-vertical-nav-secondary-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.15)); 


   
  //=== j. Modals 
  --modal-bg: var(--custom-modal-bg, var(--neutral-05));
  --modal-shadow: var(--custom-modal-shadow, #{shadow(50)} rgba(#{hex-to-rgb($blue-90)}, 0.2)); 
  --modal-overlay-bg: var(--custom-modal-overlay-bg, rgba(#{hex-to-rgb($primary-neutral-light)}, 0.5));



  //=== k. Forms
  // inputs/textarea
  --input-text-color: var(--custom-input-text-color, var(--primary-neutral));
  --input-placeholder-color: var(--custom-input-placeholder-color, var(--neutral-70));
  --input-border-color: var(--custom-input-border-color, var(--neutral-50));
  --input-bg: var(--custom-input-bg, var(--neutral-05));
  --input-shadow-color: var(--custom-input-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.12));

  // inputs - hover state
  --input-hover-border-color: var(--custom-input-hover-border-color, var(--blue-20));
  --input-hover-bg: var(--custom-input-hover-bg, var(--neutral-05));
  --input-hover-shadow-color: var(--custom-input-hover-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.2));

  // inputs - focus state
  --input-focus-border-color: var(--custom-input-focus-border-color, var(--blue-50));
  --input-focus-shadow-color: var(--custom-input-focus-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.15));

  // inputs - disabled state
  --input-disabled-text-color: var(--custom-input-disabled-text-color, var(--neutral-60));
  --input-disabled-border-color: var(--custom-input-disabled-border-color, var(--neutral-40));

  // error inputs
  --input-error-text-color: var(--custom-input-error-text-color, var(--red-80));
  --input-error-border-color: var(--custom-input-error-border-color, var(--primary-red));

  // labels for inputs
  --input-label-text-color: var(--custom-input-label-text-color, var(--primary-neutral));
  --input-label-disabled-text-color: var(--custom-input-disabled-label-text-color, var(--neutral-60));

  // inputs - primary
  --input-primary-border-color: var(--custom-input-primary-border-color, var(--green-60));
  --input-primary-shadow-color: var(--custom-input-primary-shadow-color, rgba(#{hex-to-rgb($green-60)}, 0.20));

  // inputs - primary - hover
  --input-primary-hover-border-color: var(--custom-input-primary-hover-border-color, var(--green-40));

  // inputs - primary - disabled
  --search-field-primary-disabled-button: var(--custom-search-field-primary-disabled-button, var(--neutral-05));
  --search-field-primary-disabled-button-border: var(--custom-search-field-primary-disabled-button-border, var(--neutral-40));

  // switches
  --switches-text-color: var(--custom-switches-text-color, var(--primary-neutral));
  --switches-border-color: var(--custom-switches-border-color, transparent);
  --switches-bg: var(--custom-switches-bg, var(--neutral-50));
  --switches-togglemark-bg: var(--custom-switches-togglemark-bg, var(--neutral-05));
  --switches-togglemark-shadow-color: var(--custom-switches-togglemark-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.2));

  // switches - hover
  --switches-hover-bg: var(--custom-switches-hover-bg, var(--neutral-40));

  // switches - focus
  --switches-focus-border-color: var(--custom-switches-focus-border-color, var(--blue-50));
  --switches-focus-inner-border-color: var(--custom-switches-focus-inner-border-color, var(--neutral-05));

  // switches - disabled
  --switches-disabled-text-color: var(--custom-switches-disabled-text-color, var(--neutral-70));
  --switches-disabled-bg: var(--custom-switches-disabled-bg, var(--neutral-40));
  --switches-disabled-togglemark-bg: var(--custom-switches-disabled-togglemark-bg, var(--neutral-20));

  // switches - checked
  --switches-checked-text-color: var(--custom-switches-checked-text-color, var(--neutral-05));
  --switches-checked-bg: var(--custom-switches-checked-bg, var(--primary-green));

  // switches - hover - checked
  --switches-checked-hover-bg: var(--custom-switches-checked-hover-bg, var(--green-60));


  // radio-checkbox
  --radio-checkbox-text-color: var(--custom-radio-checkbox-text-color, var(--primary-neutral));
  --radio-checkbox-border-color: var(--custom-radio-checkbox-border-color, var(--neutral-70));
  --radio-checkbox-bg: var(--custom-radio-checkbox-bg, var(--neutral-05));
  --radio-checkbox-checkmark-bg: var(--custom-radio-checkmark-bg, var(--blue-50));
  --radio-checkbox-dot-bg: var(--custom-radio-checkbox-dot-bg, var(--neutral-05));

  // radio-checkbox - focus
  --radio-checkbox-focus-border-color: var(--custom-radio-checkbox-focus-border-color, var(--blue-50));
  --radio-checkbox-error-focus-border-color: var(--custom-radio-error-checkbox-border-color, var(--primary-red));

  // radio-checkbox - disabled
  --radio-checkbox-disabled-text-color: var(--custom-radio-checkbox-disabled-text-color, var(--neutral-60));
  --radio-checkbox-disabled-border-color: var(--custom-radio-checkbox-disabled-border-color, var(--neutral-40));
  --radio-checkbox-disabled-bg: var(--custom-radio-checkbox-disabled-bg, var(--neutral-05));

  // radio-checkbox - checked
  --radio-checkbox-checked-border-color: var(--custom-radio-checkbox-checked-border-color, var(--blue-50));
  --radio-checkbox-checked-bg: var(--custom-radio-checkbox-checked-bg, var(--blue-50));

  // radio-checkbox - disabled - checked
  --radio-checkbox-checked-disabled-border-color: var(--custom-radio-checkbox-checked-disabled-border-color, var(--blue-20));
  --radio-checkbox-checked-disabled-bg: var(--custom-radio-checkbox-checked-disabled-bg, var(--blue-20));
  --radio-checkbox-checked-disabled-radio-bg: var(--custom-radio-checkbox-checked-disabled-bg, var(--neutral-05));
  --radio-checkbox-checked-disabled-checkmark-bg: var(--custom-radio-checkbox-checked-disabled-checkmark-bg, var(--neutral-05));
  --radio-checkbox-checked-disabled-dot-bg: var(--custom-radio-checkbox-checked-disabled-dot-bg, var(--neutral-70));

  // selects/dropdowns
  --select-arrow: var(--custom-select-arrow, url(#{$image-path}/arrow-medium.svg));
  --select-arrow-disabled: var(--custom-select-arrow-disabled, url(#{$image-path}/arrow-medium.svg));


  //=== l. Images
  --image-no-alt-border-color: var(--custom-image-no-alt-border-color, var(--primary-orange));
  --thumbnail-border-color: var(--custom-thumbnail-border-color, var(--neutral-50));


  //=== m. Table
  --table-border-color: var(--custom-table-border-color, var(--neutral-20));
  --table-bg: var(--custom-table-bg, var(--neutral-05));
  --table-alt-bg: var(--custom-table-alt-bg, var(--neutral-10));
  --table-shadow-color: var(--custom-table-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.1));

  // table - cell hover
  --table-hover-border-color: var(--custom-table-hover-border-color, var(--blue-10));
  --table-hover-bg: var(--custom-table-hover-bg, var(--blue-10));

  // table - cell active/pressed state
  --table-active-border-color: var(--custom-table-active-border-color, var(--neutral-20));
  --table-active-bg: var(--custom-table-active-bg, rgba(#{hex-to-rgb($neutral-90)}, 0.1));

  // table - cell active/selected state
  --table-selected-border-color: var(--custom-table-selected-border-color, var(--green-60));
  --table-selected-bg: var(--custom-table-selected-bg, var(--neutral-20));


  //=== n. Stepper
  --stepper-text-color: var(--custom-stepper-text-color, var(--neutral-80));
  --stepper-bg: var(--custom-stepper-bg, var(--neutral-40));
  --stepper-wrapper-text-color: var(--custom-stepper-wrapper-text-color, var(--primary-neutral));
  --stepper-wrapper-bg: var(--custom-stepper-wrapper-bg, var(--neutral-05));

  // stepper - active/selected state
  --stepper-active-text-color: var(--custom-stepper-active-text-color, var(--neutral-90));
  --stepper-active-border-color: var(--custom-stepper-active-border-color, var(--blue-50));
  --stepper-active-bg: var(--custom-stepper-active-bg, var(--neutral-05));
  --stepper-active-shadow-color: var(--custom-stepper-active-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.25));

  // stepper - visited state
  --stepper-visited-text-color: var(--custom-stepper-visited-text-color, var(--green-80));
  --stepper-visited-bg: var(--custom-stepper-visited-bg, var(--green-60));
  --stepper-visited-icon: var(--stepper-visited-icon-light);


  //=== o. Progress Bar
  --progress-bar-label: var(--custom-progress-bar-label, var(--neutral-80));
  --progress-bar-wrapper-bg: var(--custom-progress-bar-wrapper-bg, var(--neutral-40));
  --progress-bar-bg: var(--custom-progress-bar-bg, var(--blue-50));
  --progress-bar-shadow-color: var(--custom-progress-bar-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.25));


  //=== m. Alerts and toasts
  --alert-success-bg: var(--custom-alert-success-bg, var(--green-05));
  --alert-success-border: var(--custom-alert-success-border, var(--green-30));
  --alert-success-text: var(--custom-alert-success-text, var(--green-80));
  --alert-success-shadow: var(--custom-alert-success-shadow, #{shadow(30)} rgba(#{hex-to-rgb($primary-green)}, 0.12)); 

  --alert-info-bg: var(--custom-alert-info-bg, var(--blue-05));
  --alert-info-border: var(--custom-alert-info-border, var(--blue-20));
  --alert-info-text: var(--custom-alert-info-text, var(--blue-70));
  --alert-info-shadow: var(--custom-alert-info-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-50)}, 0.12)); 

  --alert-warning-bg: var(--custom-alert-warning-bg, var(--orange-10));
  --alert-warning-border: var(--custom-alert-warning-border, var(--orange-50));
  --alert-warning-text: var(--custom-alert-warning-text, var(--orange-90));
  --alert-warning-shadow: var(--custom-alert-warning-shadow, #{shadow(30)} rgba(#{hex-to-rgb($orange-70)}, 0.12)); 

  --alert-danger-bg: var(--custom-alert-danger-bg, var(--red-05));
  --alert-danger-border: var(--custom-alert-danger-border, var(--red-30));
  --alert-danger-text: var(--custom-alert-danger-text, var(--red-80));
  --alert-danger-shadow: var(--custom-alert-danger-shadow, #{shadow(30)} rgba(#{hex-to-rgb($red-70)}, 0.12)); 


  //=== n. Popovers and tooltips
  --popover-shadow: var(--custom-popover-shadow, #{shadow(50)} rgba(#{hex-to-rgb($blue-90)}, 0.2)); 

  //Tooltips
  --tooltip-bg: var(--custom-tooltip-bg, var(--orange-05));
  --tooltip-shadow: var(--custom-tooltip-shadow, #{shadow(30)} rgba(#{hex-to-rgb($orange-70)}, 0.15));
  --tooltip-border-color: var(--custom-tooltip-border-color, var(--orange-40));
  --tooltip-arrow-tip: var(--custom-tooltip-arrow-tip, url(#{$image-path}/tooltip-tip.svg));

  --tooltip-error-bg: var(--custom-tooltip-error-bg, var(--neutral-05));
  --tooltip-error-shadow: var(--custom-tooltip-error-shadow, #{shadow(30)} rgba(#{hex-to-rgb($red-70)}, 0.15));
  --tooltip-error-border-color: var(--custom-tooltip-error-border-color, var(--red-70));
  --tooltip-error-text: var(--custom-tooltip-error-text, var(--red-80));
  --tooltip-error-arrow-tip: var(--custom-tooltip-error-arrow-tip, url(#{$image-path}/tooltip-tip-error.svg)); 



  //=== o. Datepicker
  --datepicker-inline-bg: var(--custom-datepicker-inline-bg, var(--module-container-bg-color));
  --datepicker-popup-bg: var(--custom-datepicker-popup-bg, var(--module-container-bg-color));

  --datepicker-inline-shadow: var(--custom-datepicker-inline-shadow, var(--module-container-shadow));
  --datepicker-popup-shadow: var(--custom-datepicker-popup-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.15));

  --datepicker-arrow: var(--custom-datepicker-arrow, url(#{$image-path}/arrow-small-blue.svg));
  --datepicker-border-color: var(--custom-datepicker-border-color, var(--neutral-20));
  --datepicker-nonclickable-text-color: var(--custom-datepicker-nonclickable-text-color, var(--neutral-80));
  --datepicker-item-hover-bg: var(--custom-datepicker-item-hover-bg, var(--blue-10));
  --datepicker-item-text: var(--custom-datepicker-item-text, var(--neutral-90));
  --datepicker-item-today-border: var(--custom-datepicker-item-today-border, var(--blue-50));
  --datepicker-item-selected-bg: var(--custom-datepicker-item-selected-bg, var(--blue-60));
  --datepicker-item-selected-focus-border: var(--custom-datepicker-item-selected-focus-border, var(--neutral-05));
  --datepicker-item-selected-text: var(--custom-datepicker-item-selected-text, var(--neutral-05));
  --datepicker-item-selected-range-bg: var(--custom-datepicker-item-selected-range-bg, var(--neutral-20));
  --datepicker-item-hover-range-bg: var(--custom-datepicker-item-hover-range-bg, var(--blue-10));
  --datepicker-item-range-focus-shadow: var(--custom-datepicker-item-range-focus-shadow, var(--blue-60));
  --datepicker-item-range-focus-border: var(--custom-datepicker-item-range-focus-border, var(--neutral-05));

  --datepicker-today-btn-border: var(--custom-datepicker-today-btn-border, var(--blue-50));
  --datepicker-today-btn-text: var(--custom-datepicker-today-btn-text, var(--blue-60));

  --datepicker-clear-btn-border: var(--custom-datepicker-clear-btn-border, var(--red-50));
  --datepicker-clear-btn-text: var(--custom-datepicker-clear-btn-text, var(--red-70));


  //=== p. Tabs
  --tabs-text-color: var(--custom-tabs-text-color, var(--primary-neutral));
  --tabs-focused-text-color: var(--custom-tabs-focused-text-color, var(--primary-neutral));
  --tabs-disabled-text-color: var(--custom-tabs-disabled-text-color, var(--neutral-60));
  --tabs-content-border-color: var(--custom-tabs-content-border-color, var(--green-60));
  --tabs-content-bg: var(--custom-tabs-content-bg, var(--neutral-05));
  --tabs-content-shadow-color: var(--custom-tabs-content-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.1));

  --tabs-normal-Ypoition:                 0px;
  --tabs-disabled-Ypoition:             -50px;
  --tabs-hover-Ypoition:               -100px;
  --tabs-pressed-Ypoition:             -150px;
  --tabs-focus-Ypoition:               -200px;
  --tabs-focus-hover-Ypoition:         -250px;
  --tabs-selected-Ypoition:            -300px;
  --tabs-selected-focused-Ypoition:    -350px;

  --tabs-dropdown-Ypoition:               4px;
  --tabs-dropdown-normal-Xpoition:        4px;
  --tabs-dropdown-hover-Xpoition:       -45px;
  --tabs-dropdown-focus-Xpoition:       -94px;
  --tabs-dropdown-pressed-Xpoition:    -143px;
  --tabs-dropdown-disabled-Xpoition:   -192px;


  //=== q. Helper classes 
  //Borders
  --border-color: var(--custom-border-color, var(--neutral-50));


  //=== r. Login page
  --mask-bg: var(--custom-mask-bg, rgba(#{hex-to-rgb($blue-90)}, 0));

  --cookies-alert-bg: var(--custom-cookies-alert-bg, var(--neutral-05));
  --cookies-alert-secondary-bg: var(--custom-cookies-alert-secondary-bg, var(--neutral-20));
  --cookies-alert-text: var(--custom-cookies-alert-text, var(--neutral-90));

  --password-field-icon: var(--custom-password-field-icon, url(#{$image-path}/login/password-icon.svg));

  --visma-logo: var(--custom-visma-logo, url(#{$image-path}/visma-logo.svg));


  //=== s. Error pages 
  --error-img-opacity: var(--custom-error-img-opacity, 1);


  //=== t. Spinners
  --spinner-default-blue: var(--custom-spinner-default-blue, url(#{$image-path}/spinners/spinner_doughnut_blue_light.svg)); 
  --spinner-default-grey: var(--custom-spinner-default-grey, url(#{$image-path}/spinners/spinner_doughnut_grey_light.svg));  
  --spinner-default-green: var(--custom-spinner-default-green, url(#{$image-path}/spinners/spinner_doughnut_green_light.svg)); 

  --spinner-primary-blue: var(--custom-spinner-primary-blue, url(#{$image-path}/spinners/spinner_dotted_blue_light.svg)); 
  --spinner-primary-grey: var(--custom-spinner-primary-grey, url(#{$image-path}/spinners/spinner_dotted_grey_light.svg)); 
  --spinner-primary-green: var(--custom-spinner-primary-green, url(#{$image-path}/spinners/spinner_dotted_green_light.svg)); 

  --spinner-secondary-blue:  var(--custom-spinner-secondary-blue, url(#{$image-path}/spinners/spinner_snake_blue_light.svg)); 
  --spinner-secondary-grey:  var(--custom-spinner-secondary-grey, url(#{$image-path}/spinners/spinner_snake_grey_light.svg)); 
  --spinner-secondary-green:  var(--custom-spinner-secondary-green, url(#{$image-path}/spinners/spinner_snake_green_light.svg)); 


  //=== u. Cards list
  --card-list-text-color: var(--custom-card-list-text-color, var(--neutral-80));
  --card-list-secondary-bg: var(--custom-card-list-secondary-bg, var(--neutral-20));

  }

//
// 3. Dark mode CSS Variables
@mixin dark-mode {
  //=== a. Color palette
  //# Neutral palette
  --neutral-90: #{$neutral-dark-90};
  --neutral-80: #{$neutral-dark-80};
  --neutral-70: #{$neutral-dark-70};
  --neutral-60: #{$neutral-dark-60};
  --neutral-50: #{$neutral-dark-50};
  --neutral-40: #{$neutral-dark-40}; 
  --neutral-30: #{$neutral-dark-30};
  --neutral-20: #{$neutral-dark-20};
  --neutral-10: #{$neutral-dark-10};
  --neutral-05: #{$neutral-dark-05};

  --primary-neutral: #{$primary-neutral-dark};


  //=== b. Base body properties
  // Set site background color
  --root-background-color: var(--custom-root-dark-background-color, var(--neutral-80));
  --scrollbar-track-bg: var(--root-background-color);
  --scrollbar-thumb-bg: rgba(#{hex-to-rgb($neutral-dark-20)}, 0.35);

  // body properties
  --body-font-color: var(--custom-dark-body-font-color, var(--neutral-20));
  --titles-font-color: var(--custom-dark-titles-font-color, var(--neutral-10));

  // Components background color
  --module-container-bg-color: var(--custom-dark-module-container-bg-color, var(--neutral-70));
  --module-container-shadow: var(--custom-dark-module-container-shadow, #{shadow(10)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --module-container-divider: var(--custom-dark-module-container-divider, var(--neutral-80));
  --module-container-active-bg-color: var(--custom-dark-module-container-active-bg-color, var(--neutral-80));
  --module-container-active-before-color: var(--custom-dark-module-container-active-before-color, var(--blue-40));
  --module-container-hover-bg-color: var(--custom-dark-module-container-hover-bg-color, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));


  //=== c. Typography
  //body text
  --text-default: var(--custom-dark-text-default, var(--neutral-20));
  --text-primary: var(--custom-dark-text-primary, var(--blue-30));
  --text-disabled: var(--custom-dark-text-disabled, var(--neutral-40)); 
  --text-error: var(--custom-dark-text-error, var(--red-40)); 
  --text-warning: var(--custom-dark-text-warning, var(--orange-50));  

  // anchor/breadcrumbs properties
  --anchor-color: var(--custom-dark-anchor-color, var(--blue-30));
  --anchor-hover-color: var(--custom-dark-anchor-hover-color, var(--blue-20));
  --anchor-focus-outline: var(--custom-dark-anchor-focus-color, var(--neutral-05));
  --anchor-disabled-color: var(--custom-anchor-disabled-color, var(--neutral-40));

  // anchor/breadcrumbs - active/selected state
  --anchor-selected-color: var(--custom-anchor-selected-color, var(--neutral-20));

  // 'mark' tag/element (highlighted text)
  --highlighted-text-bg-color: var(--custom-dark-highlighted-text-bg-color, var(--primary-green));
  --highlighted-text-color: var(--custom-dark-highlighted-text-color, var(--neutral-05));

  // 'hr' tag/element
  --divider-color: var(--custom-dark-divider-color, var(--neutral-20));
  --divider-default-color: var(--custom-dark-divider-default-color, var(--neutral-40));
  --divider-primary-color: var(--custom-dark-divider-primary-color, var(--blue-50));
  --divider-disabled-color: var(--custom-dark-divider-disabled-color, var(--neutral-40));
  --divider-error-color: var(--custom-dark-divider-error-color, var(--red-40));


  //=== d. Buttons
  // default button
  --button-text-color: var(--custom-dark-button-text-color, var(--neutral-20));
  --button-border-color: var(--custom-dark-button-border-color, var(--neutral-40));
  --button-inner-border-color: var(--custom-dark-button-inner-border-color, var(--neutral-40));
  --button-bg: var(--custom-dark-button-bg, var(--neutral-60));
  --button-shadow-color: var(--custom-dark-button-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  --button-icon: var(--custom-dark-button-icon, var(--neutral-20));

  // default button - hover state
  // --button-hover-text-color: var(--custom-dark-button-hover-text-color, var(--neutral-20));
  --button-hover-border-color: var(--custom-dark-button-hover-border-color, var(--neutral-40));
  --button-hover-bg: var(--custom-dark-button-hover-bg, var(--neutral-50));
  --button-hover-shadow-color: var(--custom-dark-button-hover-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  
  // default button - focus state
  --button-focus-border-color: var(--custom-dark-button-focus-border-color, var(--neutral-05));
  --button-focus-shadow-color: var(--custom-dark-button-focus-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));

  // default button - active/pressed state
  --button-active-border-color: var(--custom-dark-button-active-border-color, var(--neutral-50));
  --button-active-bg: var(--custom-dark-button-active-bg, var(--neutral-70));

  // default button - active/selected state
  --button-selected-bg: var(--custom-dark-button-selected-bg, var(--neutral-80));

  // default button - disabled state
  --button-disabled-text-color: var(--custom-dark-button-disabled-text-color, var(--neutral-40));
  --button-disabled-border-color: var(--custom-dark-button-disabled-border-color, var(--neutral-60));
  --button-disabled-inner-border-color: var(--custom-dark-button-disabled-inner-border-color, var(--neutral-40));
  --button-disabled-bg: var(--custom-dark-button-disabled-bg, var(--neutral-60));


  // primary button
  --button-primary-text-color: var(--custom-dark-button-primary-text-color, var(--neutral-05));
  --button-primary-border-color: var(--custom-dark-button-primary-border-color, var(--blue-70));
  --button-primary-inner-border-color: var(--custom-dark-button-primary-inner-border-color, var(--blue-80));
  --button-primary-bg: var(--custom-button-dark-primary-bg, var(--blue-70));
  --button-primary-shadow-color: var(--custom-button-dark-primary-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  --button-primary-icon: var(--custom-button-primary-icon, var(--neutral-05));

  // primary button - hover state
  --button-primary-hover-border-color: var(--custom-dark-button-hover-border-color, var(--blue-60));
  --button-primary-hover-bg: var(--custom-dark-button-hover-bg, var(--blue-60));

  // primary button - focus state
  --button-primary-focus-border-color: var(--custom-dark-button-primary-focus-border-color, var(--neutral-05));
  --button-primary-focus-inner-shadow-color: var(--custom-dark-button-primary-focus-inner-shadow-color, transparent);
  --button-primary-focus-shadow-color: var(--custom-dark-button-primary-focus-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));

  // primary button - active/pressed state
  --button-primary-active-border-color: var(--custom-dark-button-primary-active-border-color, var(--blue-80));
  --button-primary-active-bg: var(--custom-dark-button-primary-active-bg, var(--blue-80));

  // primary button - active/selected state
  --button-primary-selected-bg: var(--custom-dark-button-primary-selected-bg, var(--blue-70));


  //# dropdown button
  --dropdown-menu-text-color: var(--custom-dark-dropdown-menu-text-color, var(--neutral-05));
  --dropdown-menu-bg: var(--custom-dark-dropdown-menu-bg, var(--neutral-60));
  --dropdown-menu-shadow-color: var(--custom-dark-dropdown-menu-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  // dropdown button - hover state
  --dropdown-menu-hover-bg: var(--custom-dark-dropdown-menu-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.10));

  // dropdown button - active/selected state
  --dropdown-menu-selected-text-color: var(--custom-dark-dropdown-menu-selected-text-color, var(--blue-30));

  // dropdown arrow
  --dropdown-arrow-bg: var(--custom-dark-dropdown-arrow-bg, var(--neutral-20));
  --dropdown-arrow-disabled-bg: var(--custom-dark-dropdown-arrow-disabled-bg, var(--neutral-40));

  --dropdown-primary-arrow-bg: var(--custom-dark-dropdown-primary-arrow-bg, var(--neutral-05));


  // wizard selected
  --wizard-selected-border-color: var(--custom-dark-wizard-selected-border-color, var(--blue-40));
  --wizard-checkmark-icon: var(--custom-wizard-checkmark-icon, url(#{$image-path}/wizard-checkmark-dark.svg)); 

  // wizard tabdrop
  --wizard-tabdrop-color: var(--custom-dark-wizard-tabdrop-color, var(--neutral-20));


  //=== e. Icons
  // worksourface, action and social media icons
  --icon-bg: var(--custom-dark-icon-bg, var(--blue-30));
  --icon-hover-bg: var(--custom-dark-icon-hover-bg, var(--blue-40));
  --icon-disabled-bg: var(--custom-dark-icon-disabled-bg, var(--neutral-40));

  // Informative icons
  --icon-informative-success-bg: var(--custom-dark-icon-informative-success-bg, var(--green-40));
  --icon-informative-error-bg: var(--custom-dark-icon-informative-error-bg, var(--red-40));
  --icon-informative-warning-bg: var(--custom-dark-icon-informative-warning-bg, var(--orange-50));
  --icon-informative-info-bg: var(--custom-dark-icon-informative-info-bg, var(--blue-30));
  --icon-informative-help-bg: var(--custom-dark-icon-informative-help-bg, var(--blue-30));

  // Carets and close icons
  --caret-default-bg: var(--custom-dark-caret-default-bg, var(--neutral-20));
  --caret-primary-bg: var(--custom-dark-caret-primary-bg, var(--blue-30));
  --caret-secondary-bg: var(--custom-dark-caret-secondary-bg, var(--neutral-05));
  --caret-disabled-bg: var(--custom-dark-caret-disabled-bg, var(--neutral-40));
  --caret-navigation-bg: var(--custom-dark-caret-navigation-bg, var(--neutral-20));
  --caret-open-navigation-bg: var(--custom-dark-caret-open-navigation-bg, var(--neutral-20));


  //=== f. Badges
  --badge-bg: var(--custom-dark-badge-bg, var(--neutral-05)); 
  --badge-text-color: var(--custom-dark-badge-text-color, var(--primary-neutral));
  --badge-shadow: var(--custom-dark-badge-shadow, #{shadow(20)} rgba(#{hex-to-rgb($neutral-dark-80)}, 0.15));
 
  --badge-success-bg: var(--custom-dark-badge-success-bg, var(--primary-green));
 
  --badge-warning-bg: var(--custom-dark-badge-warning-bg, var(--orange-70));

  --badge-danger-bg: var(--custom-dark-badge-danger-bg, var(--red-70));
  --badge-danger-shadow: var(--custom-dark-badge-danger-shadow, #{0 3px 6px} rgba(#{hex-to-rgb($red-80)}, 0.2));

  --badge-info-bg: var(--custom-dark-badge-info-bg, var(--blue-70));


  //=== g. List groups
  --list-group-divider: var(--custom-dark-list-group-divider, #{inset 0 1px 0 0} rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));
  --list-group-header-bg: var(--custom-dark-list-group-header-bg, var(--blue-70));
  --list-group-badge-bg: var(--custom-dark-list-group-badge-bg, rgba(#{hex-to-rgb($neutral-05)}));
  --list-group-badge-border: var(--custom-dark-list-group-badge-border, rgba(#{hex-to-rgb($neutral-05)}));
  --list-group-badge-text: var(--custom-dark-list-group-badge-text, var(--neutral-10));

  --list-group-primary-bg: var(--custom-dark-list-group-primary-bg, var(--blue-80));
  --list-group-primary-text-color: var(--custom-dark-list-group-primary-text-color, var(--neutral-10));
  --list-group-primary-divider: var(--custom-dark-list-group-primary-divider, #{inset 0 1px 0 0} rgba(#{hex-to-rgb($neutral-dark-10)}, 0.15));
  --list-group-primary-active-bg: var(--custom-dark-list-group-primary-active-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  --list-group-primary-hover-bg: var(--custom-dark-list-group-primary-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.15));


  //=== h. Navigation  
  --navigation-default-bg: var(--custom-dark-navigation-default-bg, var(--neutral-70));
  --navigation-default-shadow: var(--custom-dark-navigation-default-shadow, #{0 0px 10px} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25)); 
  --navigation-default-divider-bg: var(--custom-dark-navigation-default-divider-bg, var(--neutral-60));

  --navigation-default-second-level-bg: var(--custom-dark-navigation-default-second-level-bg, var(--neutral-70));
  --navigation-default-second-level-shadow: var(--custom-dark-navigation-default-second-level-shadow, #{shadow(20)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  --navigation-default-second-level-divider: var(--custom-dark-navigation-default-second-level-divider, var(--neutral-60));

  --navigation-default-item-text-color: var(--custom-dark-navigation-default-item-text-color, var(--neutral-10));
  --navigation-default-item-hover-bg: var(--custom-dark-navigation-default-item-hover-bg, rgba(#{hex-to-rgb($neutral-20)}, 0.1));
  --navigation-default-item-focus-border-color: var(--custom-dark-navigation-default-item-hover-bg, var(--neutral-05));
  --navigation-default-item-active-bg: var(--custom-dark-navigation-default-item-active-bg, var(--neutral-80));

  --navigation-default-icon-bg: var(--custom-dark-navigation-default-icon-bg, var(--neutral-20));
  --navigation-default-dropdown-open-icon-bg: var(--custom-dark-navigation-default-dropdown-open-icon-bg, var(--neutral-10));

  --navigation-default-item-dropdown-open-bg: var(--custom-dark-navigation-default-item-dropdown-open-bg, var(--neutral-60));
  --navigation-default-item-dropdown-open-text-color: var(--custom-dark-navigation-default-item-dropdown-open-text-color, var(--neutral-10));
  --navigation-default-item-dropdown-small-text-color: var(--custom-dark-navigation-default-item-dropdown-small-text-color, var(--neutral-20));
  --navigation-default-item-dropdown-open-small-text-color: var(--custom-dark-navigation-default-item-dropdown-open-small-text-color, var(--neutral-20));
  
  --navigation-default-item-dropdown-open-bg: var(--custom-dark-navigation-default-item-dropdown-open-bg, var(--neutral-60));
  --navigation-default-dropdown-shadow: var(--custom-dark-navigation-default-dropdown-shadow, #{0 12px 15px -3px} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --navigation-default-dropdown-text-color: var(--custom-dark-navigation-default-dropdown-text-color, var(--neutral-20));
  --navigation-default-dropdown-divider-color-dotted: var(--custom-dark-navigation-default-dropdown-divider-color-dotted, rgba(#{hex-to-rgb($neutral-20)}, 0.3));
  --navigation-default-dropdown-divider-color-strong: var(--custom-dark-navigation-default-dropdown-divider-color-strong, rgba(#{hex-to-rgb($neutral-20)}, 0.1));

  --navigation-default-attention-bg: var(--custom-dark-navigation-default-attention-bg, var(--orange-60));
  --navigation-default-attention-shadow: var(--custom-dark-navigation-default-attention-shadow, #{shadow(20)} rgba(#{hex-to-rgb($orange-80)}, 0.2));
  --navigation-default-badge-bg: var(--custom-dark-navigation-default-badge-bg, var(--badge-success-bg));
  --navigation-default-badge-text-color: var(--custom-dark-navigation-default-badge-text-color, var(--neutral-05)); 

  --navigation-default-menudrop-bg: var(--custom-dark-navigation-default-menudrop-bg, var(--neutral-20));
  --navigation-default-menudrop-shadow: var(--custom-dark-navigation-default-menudrop-shadow, #{shadow(40)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  --navigation-default-hamburger-bg: var(--custom-dark-navigation-default-hamburger-bg, var(--neutral-20));
  --navigation-default-hamburger-open-bg: var(--custom-dark-navigation-default-hamburger-bg, var(--neutral-20));

  // Secondary navigation
  --navigation-secondary-bg: var(--custom-dark-navigation-secondary-bg, var(--navigation-default-bg));
  --navigation-secondary-shadow: var(--custom-dark-navigation-secondary-shadow, var(--navigation-default-shadow)); 
  --navigation-secondary-divider-bg: var(--custom-dark-navigation-secondary-divider-bg, var(--navigation-default-divider-bg));

  --navigation-secondary-second-level-shadow: var(--custom-dark-navigation-secondary-second-level-shadow, var(--navigation-default-second-level-shadow));
  --navigation-secondary-second-level-divider: var(--custom-dark-navigation-secondary-second-level-divider, var(--navigation-default-second-level-divider));

  --navigation-secondary-item-text-color: var(--custom-dark-navigation-secondary-item-text-color, var(--navigation-default-item-text-color));
  --navigation-secondary-item-active-text-color: var(--custom-dark-navigation-secondary-item-active-text-color, var(--neutral-10));
  --navigation-secondary-item-active-font-weight: var(--custom-dark-navigation-secondary-item-active-font-weight, #{normal});
  --navigation-secondary-item-active-shadow: var(--custom-dark-navigation-secondary-item-active-shadow, #{inset 0 -4px 0px} rgba(#{hex-to-rgb($blue-50)}, 0)); 
  --navigation-secondary-item-active-bg: var(--custom-dark-navigation-secondary-item-active-bg, var(--neutral-80));
  --navigation-secondary-item-hover-bg: var(--custom-dark-navigation-secondary-item-hover-bg, var(--navigation-default-item-hover-bg));
  
  --navigation-secondary-item-dropdown-small-text-color: var(--custom-dark-navigation-secondary-item-dropdown-small-text-color, var(--neutral-20));

  --navigation-secondary-icon-bg: var(--custom-dark-navigation-secondary-icon-bg, var(--navigation-default-icon-bg));

  --navigation-secondary-menudrop-icon-bg: var(--custom-dark-navigation-secondary-menudrop-icon-bg, var(--neutral-10));
  
  --navigation-secondary-hamburger-bg: var(--custom-dark-navigation-secondary-hamburger-bg, var(--navigation-default-hamburger-bg));
  --navigation-secondary-hamburger-open-bg: var(--custom-dark-navigation-secondary-hamburger-bg, var(--navigation-default-hamburger-open-bg));
  


  //=== i. Vertical navigation 
  --vertical-nav-default-shadow: var(--custom-dark-vertical-nav-default-shadow, #{shadow(30)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25)); 
  --vertical-nav-default-item-active-bg: var(--custom-dark-vertical-nav-default-item-active-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.5));
  --vertical-nav-default-collapse-btn-bg: var(--custom-dark-vertical-nav-default-collapse-btn-bg, var(--blue-70));
  --vertical-nav-default-collapse-btn-hover-bg: var(--custom-dark-vertical-nav-default-collapse-btn-hover-bg, var(--blue-60));
  --vertical-nav-default-collapse-btn-shadow: var(--custom-dark-vertical-nav-default-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.1)); 

  --vertical-nav-secondary-bg: var(--custom-dark-vertical-nav-secondary-bg, var(--module-container-bg-color));
  --vertical-nav-secondary-shadow: var(--custom-dark-vertical-nav-secondary-shadow, var(--vertical-nav-default-shadow)); 
  --vertical-nav-secondary-item-text: var(--custom-dark-vertical-nav-secondary-item-text, var(--neutral-20));
  --vertical-nav-secondary-item-hover-bg: var(--custom-dark-vertical-nav-secondary-item-hover-bg, var(--module-container-hover-bg-color));
  --vertical-nav-secondary-item-active-bg: var(--custom-dark-vertical-nav-secondary-item-active-bg, var(--vertical-nav-default-item-active-bg));
  --vertical-nav-secondary-item-disabled: var(--custom-dark-vertical-nav-secondary-item-disabled, rgba(#{hex-to-rgb($neutral-05)}, 0.5));
  --vertical-nav-secondary-collapse-bg: var(--custom-dark-vertical-nav-secondary-collapse-bg, var(--module-container-active-bg-color));
  --vertical-nav-secondary-collapse-btn-bg: var(--custom-dark-vertical-nav-secondary-collapse-btn-bg, var(--vertical-nav-default-collapse-btn-bg));
  --vertical-nav-secondary-collapse-btn-hover-bg: var(--custom-dark-vertical-nav-secondary-collapse-btn-hover-bg, var(--vertical-nav-default-collapse-btn-hover-bg));
  --vertical-nav-secondary-collapse-arrow: var(--custom-dark-vertical-nav-secondary-collapse-arrow, var(--neutral-05));
  --vertical-nav-secondary-collapse-btn-shadow: var(--custom-dark-vertical-nav-secondary-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.1)); 



  //=== j. Modals 
  --modal-bg: var(--custom-dark-modal-bg, var(--neutral-60));
  --modal-shadow: var(--custom-dark-modal-shadow, #{shadow(50)} rgba(#{hex-to-rgb($neutral-90)}, 0.2)); 
  --modal-overlay-bg: var(--custom-dark-modal-overlay-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.5));



  //=== k. Forms
  // inputs/textarea
  --input-text-color: var(--custom-dark-input-text-color, var(--neutral-20));
  --input-placeholder-color: var(--custom-dark-input-placeholder-color, var(--neutral-20));
  --input-border-color: var(--custom-dark-input-border-color, var(--neutral-40));
  --input-bg: var(--custom-dark-input-bg, var(--neutral-80));
  --input-shadow-color: var(--custom-dark-input-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  // inputs - hover state
  --input-hover-border-color: var(--custom-dark-input-hover-border-color, var(--neutral-40));
  --input-hover-bg: var(--custom-dark-input-hover-bg, var(--neutral-70));
  --input-hover-shadow-color: var(--custom-dark-input-hover-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  // inputs - focus state
  --input-focus-border-color: var(--custom-dark-input-focus-border-color, var(--neutral-05));
  --input-focus-shadow-color: var(--custom-dark-input-focus-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  // inputs - disabled state
  --input-disabled-text-color: var(--custom-dark-input-disabled-text-color, var(--neutral-40));
  --input-disabled-border-color: var(--custom-dark-input-disabled-border-color, var(--neutral-50));

  // error inputs
  --input-error-text-color: var(--custom-dark-input-error-text-color, var(--red-40));
  --input-error-border-color: var(--custom-input-error-border-color, var(--red-40));

  // labels for inputs
  --input-label-text-color: var(--custom-dark-input-label-text-color, var(--neutral-20));
  --input-label-disabled-text-color: var(--custom-dark-input-disabled-label-text-color, var(--neutral-30));

  // inputs - primary
  --input-primary-border-color: var(--custom-dark-input-primary-border-color, var(--blue-40));
  --input-primary-shadow-color: var(--custom-dark-input-primary-shadow-color, rgba(#{hex-to-rgb($neutral-90)}, 0.25));

  // inputs - primary - hover
  --input-primary-hover-border-color: var(--custom-dark-input-primary-hover-border-color, var(--blue-30));

  // inputs - primary - disabled
  --search-field-primary-disabled-button: var(--custom-dark-search-field-primary-disabled-button, var(--blue-90));
  --search-field-primary-disabled-button-border: var(--custom-dark-search-field-primary-disabled-button-border, var(--blue-90));


  // switches
  --switches-text-color: var(--custom-dark-switches-text-color, var(--neutral-20));
  --switches-border-color: var(--custom-dark-switches-border-color, var(--neutral-40));
  --switches-bg: var(--custom-dark-switches-bg, var(--neutral-80));
  --switches-togglemark-bg: var(--custom-dark-switches-togglemark-bg, var(--neutral-05));
  --switches-togglemark-shadow-color: var(--custom-dark-switches-togglemark-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  // switches - hover
  --switches-hover-bg: var(--custom-dark-switches-hover-bg, var(--neutral-60));

  // switches - focus
  --switches-focus-border-color: var(--custom-dark-switches-focus-border-color, var(--neutral-05));
  --switches-focus-inner-border-color: var(--custom-dark-switches-focus-inner-border-color, transparent);

  // switches - disabled
  --switches-disabled-text-color: var(--custom-dark-switches-disabled-text-color, var(--neutral-40));
  --switches-disabled-bg: var(--custom-dark-switches-disabled-bg, var(--neutral-60));
  --switches-disabled-togglemark-bg: var(--custom-dark-switches-disabled-togglemark-bg, var(--neutral-50));

  // switches - checked
  --switches-checked-text-color: var(--custom-dark-switches-checked-text-color, var(--neutral-05));
  --switches-checked-bg: var(--custom-dark-switches-checked-bg, var(--blue-70));

  // switches - hover - checked
  --switches-checked-hover-bg: var(--custom-dark-switches-checked-hover-bg, var(--primary-blue));


  // radio-checkbox
  --radio-checkbox-text-color: var(--custom-dark-radio-checkbox-text-color, var(--neutral-20));
  --radio-checkbox-border-color: var(--custom-dark-radio-checkbox-border-color, var(--neutral-40));
  --radio-checkbox-bg: var(--custom-dark-radio-checkbox-bg, var(--neutral-80));
  --radio-checkbox-checkmark-bg: var(--custom-dark-radio-checkmark-bg, var(--blue-50));
  --radio-checkbox-dot-bg: var(--custom-dark-radio-checkbox-dot-bg, var(--neutral-05));

  // radio-checkbox - focus
  --radio-checkbox-focus-border-color: var(--custom-dark-radio-checkbox-focus-border-color, var(--neutral-05));
  --radio-checkbox-error-focus-border-color: var(--custom-dark-radio-error-checkbox-border-color, var(--red-40));

  // radio-checkbox - disabled
  --radio-checkbox-disabled-text-color: var(--custom-dark-radio-checkbox-disabled-text-color, var(--neutral-40));
  --radio-checkbox-disabled-border-color: var(--custom-dark-radio-checkbox-disabled-border-color, var(--neutral-50));
  --radio-checkbox-disabled-bg: var(--custom-dark-radio-checkbox-disabled-bg, var(--neutral-60));

  // radio-checkbox - checked
  --radio-checkbox-checked-border-color: var(--custom-dark-radio-checkbox-checked-border-color, var(--blue-50));
  --radio-checkbox-checked-bg: var(--custom-dark-radio-checkbox-checked-bg, var(--blue-50));

  // radio-checkbox - disabled - checked
  --radio-checkbox-checked-disabled-border-color: var(--custom-dark-radio-checkbox-checked-disabled-border-color, var(--neutral-50));
  --radio-checkbox-checked-disabled-bg: var(--custom-dark-radio-checkbox-checked-disabled-bg, var(--neutral-60));
  --radio-checkbox-checked-disabled-radio-bg: var(--custom-dark-radio-checkbox-checked-disabled-bg, var(--neutral-60));
  --radio-checkbox-checked-disabled-checkmark-bg: var(--custom-dark-radio-checkbox-checked-disabled-checkmark-bg, var(--neutral-40));
  --radio-checkbox-checked-disabled-dot-bg: var(--custom-dark-radio-checkbox-checked-disabled-dot-bg, var(--neutral-40));

  // selects/dropdowns
  --select-arrow: var(--custom-dark-select-arrow, url(#{$image-path}/arrow-medium-dark.svg));
  --select-arrow-disabled: var(--custom-dark-select-arrow-disabled, url(#{$image-path}/arrow-medium-dark.svg));



  //=== l. Images
  --image-no-alt-border-color: var(--custom-dark-image-no-alt-border-color, var(--primary-orange));
  --thumbnail-border-color: var(--custom-dark-thumbnail-border-color, var(--neutral-40));


  //=== m. Table
  --table-border-color: var(--custom-dark-table-border-color, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));
  --table-bg: var(--custom-dark-table-bg, var(--neutral-70));
  --table-alt-bg: var(--custom-dark-table-alt-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.05));
  --table-shadow-color: var(--custom-dark-table-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  // table - cell hover
  --table-hover-border-color: var(--custom-dark-table-hover-border-color, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.2));
  --table-hover-bg: var(--custom-dark-table-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));

  // table - cell active/pressed state
  --table-active-border-color: var(--custom-dark-table-active-border-color, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.2));
  --table-active-bg: var(--custom-dark-table-active-bg, var(--neutral-70));

  // table - cell active/selected state
  --table-selected-border-color: var(--custom-dark-table-selected-border-color, var(--blue-40));
  --table-selected-bg: var(--custom-dark-table-selected-bg, var(--neutral-80));


  //=== n. Stepper
  --stepper-text-color: var(--custom-dark-stepper-text-color, var(--neutral-20));
  --stepper-bg: var(--custom-dark-stepper-bg, var(--neutral-50));
  --stepper-wrapper-text-color: var(--custom-dark-stepper-wrapper-text-color, var(--neutral-20));
  --stepper-wrapper-bg: var(--custom-dark-stepper-wrapper-bg, var(--neutral-70));

  // stepper - active/selected state
  --stepper-active-text-color: var(--custom-dark-stepper-active-text-color, var(--blue-30));
  --stepper-active-border-color: var(--custom-dark-stepper-active-border-color, var(--blue-30));
  --stepper-active-bg: var(--custom-dark-stepper-active-bg, var(--neutral-80));
  --stepper-active-shadow-color: var(--custom-dark-stepper-active-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));

  // stepper - visited state
  --stepper-visited-text-color: var(--custom-dark-stepper-visited-text-color, var(--green-40));
  --stepper-visited-bg: var(--custom-dark-stepper-visited-bg, var(--green-40));
  --stepper-visited-icon: var(--stepper-visited-icon-dark);


  //=== o. Progress Bar
  --progress-bar-label: var(--custom-dark-progress-bar-label, var(--neutral-20));
  --progress-bar-wrapper-bg: var(--custom-dark-progress-bar-wrapper-bg, var(--neutral-50));
  --progress-bar-bg: var(--custom-dark-progress-bar-bg, var(--blue-50));
  --progress-bar-shadow-color: var(--custom-dark-progress-bar-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.25));
 

  //=== m. Alerts and toasts
  --alert-success-bg: var(--custom-dark-alert-success-bg, #2B453B);
  --alert-success-border: var(--custom-dark-alert-success-border, var(--green-40));
  --alert-success-text: var(--custom-dark-alert-success-text, var(--green-40));
  --alert-success-shadow: var(--custom-dark-alert-success-shadow, #{shadow(30)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15)); 

  --alert-info-bg: var(--custom-dark-alert-info-bg, #203F53);
  --alert-info-border: var(--custom-dark-alert-info-border, var(--blue-40));
  --alert-info-text: var(--custom-dark-alert-info-text, var(--blue-30));
  --alert-info-shadow: var(--custom-dark-alert-info-shadow, #{shadow(30)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15)); 

  --alert-warning-bg: var(--custom-dark-alert-warning-bg, #3B3D3B);
  --alert-warning-border: var(--custom-dark-alert-warning-border, var(--orange-70)); 
  --alert-warning-text: var(--custom-dark-alert-warning-text, var(--orange-50));
  --alert-warning-shadow: var(--custom-dark-alert-warning-shadow, #{shadow(30)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15)); 

  --alert-danger-bg: var(--custom-dark-alert-danger-bg, #39313D);
  --alert-danger-border: var(--custom-dark-alert-danger-border, var(--red-50));
  --alert-danger-text: var(--custom-dark-alert-danger-text, var(--red-40));
  --alert-danger-shadow: var(--custom-dark-alert-danger-shadow, #{shadow(30)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15)); 
  

  //=== n. Popovers and tooltips
  --popover-shadow: var(--custom-dark-popover-shadow, #{shadow(50)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.2));

  //Tooltips
  --tooltip-bg: var(--custom-dark-tooltip-bg, var(--neutral-60));
  --tooltip-shadow: var(--custom-dark-tooltip-shadow, #{shadow(40)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --tooltip-border-color: var(--custom-dark-tooltip-border-color, var(--neutral-40));
  --tooltip-arrow-tip: var(--custom-dark-tooltip-arrow-tip, url(#{$image-path}/tooltip-tip-dark.svg));

  --tooltip-error-bg: var(--custom-dark-tooltip-error-bg, var(--neutral-60));
  --tooltip-error-shadow: var(--custom-dark-tooltip-error-shadow, #{shadow(40)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --tooltip-error-border-color: var(--custom-dark-tooltip-error-border-color, var(--red-40));
  --tooltip-error-text: var(--custom-dark-tooltip-error-text, var(--red-40));
  --tooltip-error-arrow-tip: var(--custom-dark-tooltip-error-arrow-tip, url(#{$image-path}/tooltip-tip-error-dark.svg));



  //=== o. Datepicker
  --datepicker-inline-bg: var(--custom-dark-datepicker-inline-bg, var(--module-container-bg-color));
  --datepicker-popup-bg: var(--custom-dark-datepicker-popup-bg, var(--neutral-60));

  --datepicker-inline-shadow: var(--custom-datepicker-inline-shadow, var(--module-container-shadow));
  --datepicker-popup-shadow: var(--custom-datepicker-popup-shadow, #{shadow(30)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  --datepicker-arrow: var(--custom-dark-datepicker-arrow, url(#{$image-path}/arrow-small-blue-dark.svg));
  --datepicker-border-color: var(--custom-dark-datepicker-border-color, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));
  --datepicker-nonclickable-text-color: var(--custom-dark-datepicker-nonclickable-text-color, var(--neutral-20));
  --datepicker-item-text: var(--custom-dark-datepicker-item-text, var(--neutral-10));
  --datepicker-item-hover-bg: var(--custom-dark-datepicker-item-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));
  --datepicker-item-today-border: var(--custom-dark-datepicker-item-today-border, var(--blue-50));
  --datepicker-item-selected-bg: var(--custom-dark-datepicker-item-selected-bg, var(--blue-60));
  --datepicker-item-selected-focus-border: var(--custom-dark-datepicker-item-selected-focus-border, var(--neutral-05));
  --datepicker-item-selected-text: var(--custom-dark-datepicker-item-selected-text, var(--neutral-05));
  --datepicker-item-selected-range-bg: var(--custom-dark-datepicker-item-selected-range-bg, var(--neutral-60));
  --datepicker-item-hover-range-bg: var(--custom-dark-datepicker-item-hover-range-bg, var(--neutral-60));
  --datepicker-item-range-focus-shadow: var(--custom-dark-datepicker-item-range-focus-shadow, var(--blue-60));
  --datepicker-item-range-focus-border: var(--custom-dark-datepicker-item-range-focus-border, var(--neutral-05));

  --datepicker-today-btn-border: var(--custom-dark-datepicker-today-btn-border, var(--neutral-40));
  --datepicker-today-btn-text: var(--custom-dark-datepicker-today-btn-text, var(--neutral-10));
  --datepicker-clear-btn-border: var(--custom-dark-datepicker-clear-btn-border, var(--red-40));
  --datepicker-clear-btn-text: var(--custom-dark-datepicker-clear-btn-text, var(--red-40));


  //=== p. Tabs
  --tabs-text-color: var(--custom-dark-tabs-text-color, var(--neutral-10));
  --tabs-focused-text-color: var(--custom-dark-tabs-focused-text-color, var(--neutral-05));
  --tabs-disabled-text-color: var(--custom-dark-tabs-disabled-text-color, var(--neutral-40));
  --tabs-content-border-color: var(--custom-dark-tabs-content-border-color, var(--blue-40));
  --tabs-content-bg: var(--custom-dark-tabs-content-bg, var(--neutral-70));
  --tabs-content-shadow-color: var(--custom-dark-tabs-content-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  --tabs-normal-Ypoition:              -400px;
  --tabs-disabled-Ypoition:            -450px;
  --tabs-hover-Ypoition:               -500px;
  --tabs-pressed-Ypoition:             -550px;
  --tabs-focus-Ypoition:               -600px;
  --tabs-focus-hover-Ypoition:         -650px;
  --tabs-selected-Ypoition:            -700px;
  --tabs-selected-focused-Ypoition:    -750px;

  --tabs-dropdown-Ypoition:               4px;
  --tabs-dropdown-normal-Xpoition:     -241px;
  --tabs-dropdown-hover-Xpoition:      -290px;
  --tabs-dropdown-focus-Xpoition:      -339px;
  --tabs-dropdown-pressed-Xpoition:    -388px;
  --tabs-dropdown-disabled-Xpoition:   -437px;


  //=== q. Helper classes 
  //Borders
  --border-color: var(--custom-dark-border-color, var(--neutral-40));



  //=== r. Login page
  --mask-bg: var(--custom-dark-mask-bg, rgba(#{hex-to-rgb($blue-90)}, 0.67));

  --cookies-alert-bg: var(--custom-dark-cookies-alert-bg, rgba(#{hex-to-rgb($neutral-dark-80)}, 0.5));
  --cookies-alert-secondary-bg: var(--custom-dark-cookies-alert-secondary-bg, var(--cookies-alert-bg));
  --cookies-alert-text: var(--custom-dark-cookies-alert-text, var(--neutral-20));

  --password-field-icon: var(--custom-dark-password-field-icon, url(#{$image-path}/login/password-icon-dark.svg));
  
  --visma-logo: var(--custom-dark-visma-logo, url(#{$image-path}/visma-logo-dark.svg));


  //=== s. Error pages 
  --error-img-opacity: var(--custom-dark-error-img-opacity, 0.6);


  //=== t. Spinners
  --spinner-default-blue: var(--custom-dark-spinner-default-blue, url(#{$image-path}/spinners/spinner_doughnut_blue_dark.svg)); 
  --spinner-default-grey: var(--custom-dark-spinner-default-grey, url(#{$image-path}/spinners/spinner_doughnut_grey_dark.svg)); 
  --spinner-default-green: var(--custom-dark-spinner-default-green, url(#{$image-path}/spinners/spinner_doughnut_green_dark.svg)); 

  --spinner-primary-blue: var(--custom-dark-spinner-primary-blue, url(#{$image-path}/spinners/spinner_dotted_blue_dark.svg)); 
  --spinner-primary-grey: var(--custom-dark-spinner-primary-grey, url(#{$image-path}/spinners/spinner_dotted_grey_dark.svg)); 
  --spinner-primary-green: var(--custom-dark-spinner-primary-green, url(#{$image-path}/spinners/spinner_dotted_green_dark.svg)); 

  --spinner-secondary-blue:  var(--custom-dark-spinner-secondary-blue, url(#{$image-path}/spinners/spinner_snake_blue_dark.svg)); 
  --spinner-secondary-grey:  var(--custom-dark-spinner-secondary-grey, url(#{$image-path}/spinners/spinner_snake_grey_dark.svg)); 
  --spinner-secondary-green:  var(--custom-dark-spinner-secondary-green, url(#{$image-path}/spinners/spinner_snake_green_dark.svg)); 


  //=== u. Cards list
  --card-list-text-color: var(--custom-dark-card-list-text-color, var(--neutral-20));
  --card-list-secondary-bg: var(--custom-dark-card-list-secondary-bg, var(--neutral-60));
  }
