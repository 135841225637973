/*
 * Login page - default
 */

.login-page {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 3.2rem;
    padding-bottom: 0;

    &.login-page-default-bg-1 { background-image: url('#{$image-path}/login/login-background-default-1.jpg'); }
    &.login-page-default-bg-2 { background-image: url('#{$image-path}/login/login-background-default-2.jpg'); }
    &.login-page-default-bg-3 { background-image: url('#{$image-path}/login/login-background-default-3.jpg'); }
    &.login-page-default-bg-4 { background-image: url('#{$image-path}/login/login-background-default-4.jpg'); }
    &.login-page-default-bg-5 { background-image: url('#{$image-path}/login/login-background-default-5.jpg'); }
    
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--mask-bg);
    }

    .cookies-alert { 
        justify-content: center; 
        top: 0;
        margin-bottom: 2.4rem;
    } 

    .login-footer-alert { 
        justify-content: right; 
        bottom: 0;
        margin-top: 2.4rem;

        @media (max-width: $screen-md-min) {
            justify-content: center;
        }
    }

    .login-box { box-shadow: var(--modal-shadow); }
}