
//=== Basic vertical nav style

.page-holder { 
    padding-left: 300px;  
    transition: padding-left 0.2s ease-in-out;

    @media (max-width: $screen-md-min) {
        padding-left: 50px;  
    }

    &.hidden-vert-menu {
        padding-left: 90px;
        transition: padding-left 0.2s ease-in-out;

        @media (max-width: $screen-md-min) {
            padding-left: 50px;  
        }

        + footer {
            padding-left: 90px;
            transition: padding 0.2s ease-in-out;
        }
    }

    + footer {
        padding-left: 300px;
        padding-right: 30px;
        transition: padding 0.2s ease-in-out;
    }    
}

.vertical-nav {
    top: 66px; // When top navigation has only one level
    height: calc(100vh - 66px); // When top navigation has only one level
    left: 0;
    position: fixed;
    padding-bottom: 122px;
    z-index: 1;
    overflow: hidden;
    width: 300px;
	transition: width 0.2s ease-in-out;

    &:after {
        content: '';
        display: none;
        position: absolute;
        width: 90px;
        height: 100%;
        top: 0;
        right: -90px;
    }

    &.hidden-menu {
        width: 90px;

        @media (max-width: $screen-md-min) {
            width: 50px;  
        }

        &:after { 
            display: block; 
            transition: width 0.2s ease-in-out;
        }

        ul li.show-nav > a:after { 
            transform: rotateY(180deg); 
            transition: width 0.2s ease-in-out;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
 
        li {
            list-style: none;
            margin: 0;
            padding: 0;

            a {
                text-decoration: none;
                display: block;
                position: relative;

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 19px;
                    right: 15px;
                    @include caret-large-default();
                }

                &:only-child:after { display: none; }
            }

            &.active,
            &.active.active-child {
                > a { 
                    &:after { transform: rotate(180deg); }
                }
            }

            &.disabled {
                > a { 
                    pointer-events: none;

                    &:hover { cursor: not-allowed; }

                    &:focus { outline: none; }
                }
            }

            &.show-nav {
                position: absolute;
                right: 0;
                bottom: 50px;

                > a {
                    width: 30px;
                    height: 65px;
                    display: block;
                    text-indent: -9999px;
                    padding: 0 !important;
                    mask: url(#{$image-path}/vertical-nav-collapse-btn.svg);
                    -webkit-mask: url(#{$image-path}/vertical-nav-collapse-btn.svg);
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;

                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 27px;
                        right: 7px;
                        height: 14px;
                        width: 14px;
                        mask: url(#{$image-path}/vertical-nav-collapse-arrow.svg);
                        -webkit-mask: url(#{$image-path}/vertical-nav-collapse-arrow.svg);
                        mask-repeat: no-repeat;
                        -webkit-mask-repeat: no-repeat;
                        transition: width 0.2s ease-in-out;
                    }
                }

                &.show-nav-primary {
                    > a {
                        width: 30px;
                        height: 30px;
                        border-radius: 5px 0 0 5px;
                        mask: none;
                        -webkit-mask: none;

                        &:after {
                            top: 9px;
                        }
                    }
                }
            }
        }
    } 

    ul { 
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: content-box;
        width: 300px;
        height: calc(100vh - 66px); // When top navigation has only one level
        scrollbar-width: none;
        padding-bottom: 120px;

        ul {
            padding: 0;
            height: auto;
        }

        > li {
            > a { padding: 12px 30px 12px 22px; }

            &.active, &.active-child {
                > ul { display: block; }
            }

            > ul {
                padding-bottom: 6px;
                display: none;
                
                > li {
                    > a { padding: 9px 30px; }
                }
            }
        }
    }
}


/* When top navigation/main nav has 2 levels */
.nav-space {
    .vertical-nav {
        @media (min-width: $screen-sm-min) {
            top: 114px; // When top navigation has two levels
            height: calc(100vh - 114px); // When top navigation has two levels
        }

        > ul {
            @media (min-width: $screen-sm-min) {
                height: calc(100vh - 114px); // When top navigation has 2 levels level
            }
        }
    }
}
