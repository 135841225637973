
// Input in Groups
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 6px 6px 0;

    align-items: stretch;

    // wrapper for the cta in the group
    .input-group-btn {
        display: flex;

        &, &.dropdown {
            margin: 0;
            z-index: 1;

            .btn {  margin: 0; }
        }
    }

    .btn-group, .dropdown {
        margin: 0;

        .btn {
            &:hover { z-index: 2; }
            &:focus { z-index: 3; }
        }

        &:not(:last-child) {
            .dropdown-toggle {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            .btn:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .dropdown.open,
    .btn-split.open {
        z-index: 4;
    }

    > .btn {
        margin: 0;

        &:hover { z-index: 2; }
        &:focus { z-index: 3; }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // styling the input
    > .form-control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin: 0;
        z-index: 1;

        &:not(:first-child) { margin-left: -1px; }
        &:not(:last-child) { margin-right: -1px; }

        &:hover { z-index: 2; }
        &:focus { z-index: 3; }
    }
}

// Styling wrapper for grouping form elements
.form-group {
    clear: both;
    margin-bottom: 12px;
    position: relative;

    .btn { margin-bottom: 0; }

    label {
        padding: 5px 6px 6px 0;
        display: inline-block;
        position: relative;
        line-height: 1.4;
        // align-self: center; - not ok in our case because the label for textarea we need it to be at the start, not centered

        color: var(--input-label-text-color);

        &.checkbox, &.radio {
            padding: 1px 0 1px 19px;
        }
    }

    .disabled &, :disabled &,
    &.disabled, &:disabled {
        label, .label, .holder {
            color: var(--input-label-disabled-text-color);
        }
    }

    // Required fields
    &.required  {
        > label::before,
        > a::before {
            position: absolute;
            content: "*";
            margin-left: -7px;
            top: 5px;

            color: var(--input-error-text-color);
            font-size: 12px;
        }
    }

    .form-horizontal & {
        display: flex;
        flex-wrap: wrap;

        label + [class*="col-"] { padding: 0; }
    }

    // form elements inline
    .form-inline & {
        display: inline-block;
        padding-right: 12px;

        .form-control {
            width: auto;

            @media (min-width: $screen-sm-min) {
                display: inline-block;
                margin-bottom: 0;

                &.select-wrapper select { margin-top: -1px; }
            }
        }
    }

    .input-group {
        margin: 0;
        flex-wrap: nowrap;
    }
}

//
.form-group-lg {
    input{
        &, &.form-control {
            height: 48px;
        }
    }

    label, .control-label {
        height: 48px;
        padding-top: 12px;
        line-height: 24px;
    }
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .btn { margin-bottom: 12px; }

    .checkbox,
    .radio {
        padding-right: 12px;
        margin-bottom: 16px;
    }
} 

.form-horizontal {
    .form-group > [class*="offset-"] { padding: 0; }
}

// wrapper for text to be align with inputs
.form-control-static {
    min-height: 30px;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 6px;
}

// Help block
.help-block {
    display: block;
    margin-bottom: 10px;
    color: $neutral-80
    }