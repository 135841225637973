
//# General input styles

// Input placeholder style
::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
    color: var(--input-placeholder-color);
    font-style: italic;
}

.form-control {
    display: block;
    width: 100%;
}

/* Change Autocomplete styles in Chrome*/
input, textarea {
    &:-internal-autofill-selected  {
        color: -internal-light-dark(var(--input-text-color), var(--input-text-color)) !important;
    }

    &:-webkit-autofill  {
        -webkit-box-shadow: 0 0 0px 1000px var(--input-bg) inset !important;
        box-shadow: 0 0 0px 1000px var(--input-bg) inset !important;

        &, &:hover, &:focus {
            -webkit-text-fill-color: var(--input-text-color);
        }

        &:hover {
            box-shadow: 0 0 0px 1000px var(--input-hover-bg) inset, $shadow-20 var(--input-hover-shadow-color) !important;
        }

        &:focus {
            box-shadow: inset 0 0 0 1px var(--input-focus-border-color), 0 0 0px 1000px var(--input-bg) inset , $shadow-20 var(--input-focus-shadow-color) !important;
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {
            -webkit-text-fill-color: var(--input-disabled-text-color);
            box-shadow: 0 0 0px 1000px var(--input-bg) inset !important;
        }
    }
}

// upload button for FireFox browsers.
input[type=file]::file-selector-button {
    cursor: pointer;
    height: 30px;
    padding: 3px 15px;

    color: var(--button-text-color);

    font: {
        size: $font-size-sm;
        weight: $normal;
    }
    line-height: 1.8rem;

    outline: none;
    border-radius: $border-radius-5;
    border: 1px solid var(--button-border-color);
    background-color: var(--button-bg);
    box-shadow: $shadow-20 var(--button-shadow-color);

    &:focus {
        outline: none;
        border-color: var(--button-focus-border-color);
        box-shadow: inset 0 0 0 1px var(--button-focus-border-color), $shadow-20 var(--button-focus-shadow-color);
    }
}

// upload button for Chrome browsers.
// &[type=file]::-ms-browse,
input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
    height: 30px;
    padding: 3px 15px;

    color: var(--button-text-color);

    font: {
        size: $font-size-sm;
        weight: $normal;
    }
    line-height: 1.8rem;

    outline: none;
    border-radius: $border-radius-5;
    border: 1px solid var(--button-border-color);
    background-color: var(--button-bg);
    box-shadow: $shadow-20 var(--button-shadow-color);

    &:hover, &.hover {
        border-color: var(--button-hover-border-color);
        background-color: var(--button-hover-bg);
        box-shadow: $shadow-20 var(--button-hover-shadow-color);
    }

    &:focus {
        outline: none;
        border-color: var(--button-focus-border-color);
        box-shadow: inset 0 0 0 1px var(--button-focus-border-color), $shadow-20 var(--button-focus-shadow-color);
    }

    &:active, &.active {
        border-color: var(--button-active-border-color);
        background-color: var(--button-active-bg);
        box-shadow: none;
    }

    &:disabled, &.disabled,
    :disabled &, .disabled & {
        color: var(--button-disabled-text-color);

        border-color: var(--button-disabled-border-color);
        background-color: var(--button-disabled-bg);
        box-shadow: none;
    }
}


// Base 'input' tag style
input {
    &, &.form-control {
        box-sizing: border-box;
        height: 30px;
        // margin-right: 6px;

        font: $font-sm;
        line-height: 2.4rem;

        //### input base, focus state
        &.focus, &:focus {
            outline: 0;

            border-color: var(--input-focus-border-color);
            box-shadow: inset 0 0 0 1px var(--input-focus-border-color), $shadow-20 var(--input-focus-shadow-color);
        }

        //### input base, disabled state
        .disabled &, :disabled &,
        &.disabled, &:disabled {
            cursor: default; 
            pointer-events: none;
        }

        //### 'default' input style
        &[type="text"],
        &[type="password"],
        &[type="datetime"],
        &[type="datetime-local"],
        &[type="date"],
        &[type="month"],
        &[type="time"],
        &[type="week"],
        &[type="number"],
        &[type="email"],
        &[type="url"],
        &[type="search"],
        &[type="tel"],
        &[type="color"] {
            padding: 4px 12px 5px;

            color: var(--input-text-color);

            border-radius: 0;
            border: 1px solid var(--input-border-color);
            background-color: var(--input-bg);

            -webkit-appearance: none;
            -moz-appearance: textfield;

            &.hover, &:hover {
                border-color: var(--input-hover-border-color);
                background-color: var(--input-hover-bg);
                box-shadow: $shadow-20 var(--input-hover-shadow-color);
            }

            &.focus, &:focus {
                border-color: var(--input-focus-border-color);
                box-shadow: inset 0 0 0 1px var(--input-focus-border-color), $shadow-20 var(--input-focus-shadow-color);
            }

            .disabled &, :disabled &,
            &.disabled, &:disabled {
                color: var(--input-disabled-text-color);

                border-color: var(--input-disabled-border-color);
                background-color: var(--input-bg);
                box-shadow: none;

                &::placeholder,
                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder {
                    color: var(--input-disabled-text-color);
                }
            }
        }

        // the clear "X" button for text inputs on IE/Edge.
        &:-ms-clear { display:none; }

        // validation state
        .has-error &, &.has-error {
            &, &.hover, &:hover, &.focus, &:focus {
                color: var(--input-error-text-color);

                border-color: var(--input-error-border-color);
                box-shadow: none;
            }

            &.focus, &:focus {
                box-shadow: inset 0 0 0 1px var(--input-error-border-color);
            }
        }

        // large inputs
        &.input-lg { height: 48px; }

        // small inputs
        &.input-sm { 
            height: 24px; 
            padding-bottom: 2px;
            padding-top: 2px;
        }

        // hide controls for inputs type numbers
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }

        //
        &[type="number"] {
            &.right-direction {
                &, .form-group & {
                    padding: 7px 10px 5px 0;
                }
            }

            &.spinner {
                -moz-appearance: number-input;
            }
        }

        //
        &[type="number"],
        &[type="datetime"],
        &[type="datetime-local"],
        &[type="date"],
        &[type="month"],
        &[type="week"],
        &[type="time"] {
            &.spinner {
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: inner-spin-button;
                }
            }
        }

        // upload input type / file type
        &[type="file"] {
            padding-top: 0px;
            padding-left: 3px; 
        }
    }
}

// Base 'textarea' tag style
textarea {
    &, &.form-control {
        height: initial;
        // margin-right: 6px;
        padding: 1px 12px 4px 12px;

        color: var(--input-text-color);
        font: $font-sm;
        line-height: 2.4rem;

        border-radius: 0;
        border: 1px solid var(--input-border-color);
        background-color: var(--input-bg);
        box-shadow: none;

        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        //### base textarea, hover state
        &.hover, &:hover {
            border-color: var(--input-hover-border-color);
            box-shadow: $shadow-20 var(--input-hover-shadow-color);
        }

        //### base textarea, focus state
        &.focus, &:focus {
            outline: 0;
            border-color: var(--input-focus-border-color);
            box-shadow: inset 0 0 0 1px var(--input-focus-border-color), $shadow-20 var(--input-focus-shadow-color);
        }

        //### base textarea, disabled state
        .disabled &, :disabled &,
        &.disabled, &:disabled {
            cursor: default;
            pointer-events: none;

            color: var(--input-disabled-text-color);

            border-color: var(--input-disabled-border-color);
            box-shadow: none !important;
        }

        //### validation state
        .has-error &, &.has-error {
            &, &.hover, &:hover, &.focus, &:focus {
                color: var(--input-error-text-color);

                border-color: var(--input-error-border-color);
                box-shadow: none;
            }

            &.focus, &:focus {
                box-shadow: inset 0 0 0 1px var(--input-error-border-color);
            }
        }
    }
}

// Base 'select' tag style
select {
    &, &.form-control {
        cursor: pointer;
        min-width: 160px;
        max-width: 100%;
        height: 30px;
        padding: 3px 18px 3px 12px;
            
        color: var(--button-text-color);

        font: { 
            size: $font-size-sm;
            weight: $normal;
        }

        line-height: 1.8rem;

        text: {
            align: left;
            overflow: ellipsis;
            shadow: none;
        }

        vertical-align: middle;
        white-space: nowrap;

        border: 1px solid var(--button-border-color);
        border-radius: $border-radius;
        background: {
            color: var(--button-bg);
            image: var(--select-arrow);
            repeat: no-repeat;
            size: 9px 5px;
            position: right 10px top 12px;
        }
        box-shadow: $shadow-20 var(--button-shadow-color);

        appearance: none;

        // hide arrow in IE/Edge
        &::-ms-expand {
            display: none;
        }

        //### cta button hover state
        &:hover {
            border-color: var(--button-hover-border-color);
            background-color: var(--button-hover-bg);
            box-shadow: $shadow-20 var(--button-hover-shadow-color);
        }

        //### cta button focus state
        &:focus:not(:active) {
            outline: 0;
            border-color: var(--button-focus-border-color);
            box-shadow: inset 0 0 0 1px var(--button-focus-border-color), $shadow-20 var(--button-hover-shadow-color);
        }

        //### cta button active/pressed state
        &:active {
            outline: 0;
            border-color: var(--button-active-border-color);
            background-color: var(--button-active-bg);
            box-shadow: none;
        }

        //### cta button disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            pointer-events: none;
            color: var(--button-disabled-text-color);

            border-color: var(--button-disabled-border-color);
            background-color: var(--button-disabled-bg);
            background-image: var(--select-arrow-disabled);
            box-shadow: none;
        }

        > option {
            background-color: var(--dropdown-menu-bg);

            &:hover {
                background-color: var(--dropdown-menu-hover-bg);
            }
        }

        // large inputs
        &.input-lg {
            height: 48px;
            background-position: right 6px top 18px;
        }

        .has-error &, &.has-error {
            border-color: var(--input-error-border-color);
            box-shadow: none;

            &:focus:not(:active) {
                border-color: var(--input-error-border-color);
                box-shadow: inset 0 0 0 1px var(--input-error-border-color);
            }
        }
    }
}

// full width for inputs and select when in grid
input, textarea, select {
    &, &.form-control {
        [class*="col-xs-"] > &,
        [class*="col-sm-"] > &,
        [class*="col-md-"] > &,
        [class*="col-lg-"] > & {
            width: 100%;
        }
    }
}


// read only state for input and textarea
input, textarea {
    &, &.form-control {
        &[readonly] {
            cursor: default;

            &, &.hover, &:hover,
            &.focus, &:focus,
            .disabled &, :disabled &,
            &.disabled, &:disabled {
                cursor: default;
                outline: 0;
                border: 0;
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
}


// error indicator/icon
.has-error .vismaicon.vismaicon-error,
.has-error ~ .vismaicon.vismaicon-error {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-8px);
    cursor: pointer;
}

.checkbox.has-error .vismaicon.vismaicon-error,
.has-error .checkbox .vismaicon.vismaicon-error,
.has-error .checkbox .vismaicon.vismaicon-error,
.has-error .checkbox .vismaicon.vismaicon-error,
.has-error .radio .vismaicon.vismaicon-error,
.has-error .radio .vismaicon.vismaicon-error,
.radio.has-error .vismaicon.vismaicon-error,
.radio.has-error .vismaicon.vismaicon-error {
    right: -24px;
}

.has-error .vismaicon-error:hover ~ .tooltip.tooltip-error,
.has-error ~ .vismaicon-error:hover ~ .tooltip.tooltip-error {
    display: block;
    cursor: pointer;
}

