// Cards list - .card-list-primary

.card-list-secondary {
    .card-list-item {
        background-color: var(--card-list-secondary-bg);
        border-radius: $border-radius-5;
        padding: 1.6rem 0rem;
    
        > * {
            padding: 0.8rem 3rem;
        }

        .description {
            flex-direction: column;
            align-items: initial;
            font-weight: $semibold;
        
            .date { 
                color: var(--card-list-text-color);
                font-weight: $normal; 
            }

            p { margin: 0; }
        }

        .action {
            p { margin: 0; }

            .vismaicon { margin: 0 .6rem; }
        }

        .divider {
            border-color: var(--module-container-divider);
        }
    }
}