
// base style for fieldset tags.
fieldset {
    margin: 0 0 30px 0;
    padding: 0;

    border: 0;

    legend {
        display: block;
        margin: 0 0 12px;
        padding: 0;

        font-size: $font-size-md;
        line-height: 1.8rem;
    }
}
