//### 'clearfix' mixin used on wrappers with floats elements
@mixin clearfix() {
    &::after {
        display: block;
        visibility: hidden;
        content: "";
        clear: both;

        height: 0;
    }
}


//### Grid system
// Generate semantic grid columns with these mixins.
@mixin make-container() {
    width: 100%;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    margin-right: auto;
    margin-left: auto;
    } 
  
// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
        max-width: $container-max-width;
        }
    }
}
  
@mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: ($grid-gutter-width / -2);
    margin-left: ($grid-gutter-width / -2);
    }

@mixin make-col-ready() {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by always setting `width: 100%;`. This works because we use `flex` values later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    }
  
@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out the width of the column. Applies to IE10+ and Firefox. Chrome and Safari do not appear to require this.
    max-width: percentage($size / $columns);
    }

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: $size / $columns;
    margin-left: if($num == 0, 0, percentage($num));
    }

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    // Common properties for all breakpoints 
    %grid-column {
      position: relative;
      width: 100%;
      min-height: 1px; // Prevent columns from collapsing when empty
      padding-right: ($gutter / 2);
      padding-left: ($gutter / 2);
    }
  
    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @extend %grid-column;
        }
      }

      .col#{$infix},
      .col#{$infix}-auto {
        @extend %grid-column; 
      }
  
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        // Provide basic `.col-{bp}` classes for equal-width flexbox columns
        .col#{$infix} {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
        }

        .col#{$infix}-auto {
          flex: 0 0 auto;
          width: auto;
          max-width: none; // Reset earlier grid tiers
        }
  
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
  
        .order#{$infix}-first { order: -1; }
  
        .order#{$infix}-last { order: $columns + 1; }
  
        @for $i from 0 through $columns {
          .order#{$infix}-#{$i} { order: $i; }
        }
  
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }



// Responsive image. Keep images from scaling beyond the width of their parents.
@mixin img-fluid() {
  max-width: 100%;
  height: auto;
}

// Single side border-radius
@mixin border-radius($radius: $border-radius) {
  border-radius: $radius;
}

@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-top-left-radius($radius) {
  border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
  border-top-right-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
  border-bottom-left-radius: $radius;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
