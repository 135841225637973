// Datepicker Popup

.datepicker {
    .dropdown { 
        .dropdown-menu { 
            display: block;  
            width: 366px;
            padding: 25px 35px;
            top: 100%;
            z-index: 1;
            box-shadow: var(--datepicker-popup-shadow);  
            background: var(--datepicker-popup-bg);

            @media(max-width: $screen-xs-min) {
                padding-left: 25px;
                padding-right: 25px;
            }
        }

        input[type="text"] {
            padding-right: 30px;
  
            + .icon {
                display: block;
                width: 20px;
                height: 20px; 
                position: absolute;
                right: 7px;
                top: 5px;
                mask: url(#{$image-path}/datepicker-icon.svg);
                -webkit-mask: url(#{$image-path}/datepicker-icon.svg);
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;
                mask-position: right 0px top 0px;
                -webkit-mask-position: right 0px top 0px;
                background-color: var(--icon-bg);
            }
        }
    }
}

// Label
.field {
    label.label {
        background-color: transparent;
        font-weight: normal;
        font: $font-sm;
        min-width: 1%;
        text-align: left;
        margin: 0;
        top: auto;
        border-radius: 0;
    }
}