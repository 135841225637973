// Stepper - horizontal (default)
body.nav-space {   
    @media (max-width: $screen-md-min) { 
        .stepper:not(.stepper-vertical) {
            top: 115px;

            + .container { margin-top: 115px; }
        }
    }
} // this applies for the case where the top navigation has 2 levels


body {
    @media (max-width: $screen-sm-min) { 
        .stepper:not(.stepper-vertical) {
            top: 67px;

            + .container { margin-top: 70px; }
        }
    }
} 

.stepper:not(.stepper-vertical) {
    @include make-container();
    @include make-container-max-widths(); 
    margin: 0 auto 50px;

    @media (max-width: $screen-md-min) { 
        max-width: 100%;
    }

    ul {
        flex-direction: row;

        > li {
            > * {
                text-align: center;
                min-height: 75px;

                &:before {
                    right: 0;
                    left: auto;
                    bottom: 4px;
                }

                span {
                    position: absolute;
                    text-align: center;
                    left: auto;
                    right: calc(-50% + 20px);
                    padding: 0 5px;
                }
            }

            // &.active {
            //     > * {
            //         span:not(.step) { 
            //             top: 0px; 
            //         }
            //     }
            // }

            /* 2 items in the stepper  */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -95.6%; }
                }

                &.active:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -94.2%; }
                }
            }

            /* 3 items in the stepper  */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -46.7%; }
                }

                &.active:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -45.7%; }
                }
            }

            /* 4 items in the stepper  */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -30.4%; }
                }

                &.active:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -29.4%; }
                }
            }

            /* 5 items in the stepper  */
            &:first-child:nth-last-child(5),
            &:first-child:nth-last-child(5) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -22.3%; }
                }

                &.active:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -21.3%; }
                }
            }

            /* 6 items in the stepper  */
            &:first-child:nth-last-child(6),
            &:first-child:nth-last-child(6) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -17.3%; }
                }

                &.active:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -16.5%; }
                }
            }

            /* 7 items in the stepper  */
            &:first-child:nth-last-child(7),
            &:first-child:nth-last-child(7) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -14.1%; }
                }

                &.active:first-child { 
                    @media (min-width: $screen-md-min + 1) { margin-left: -13.3%; }
                }
            }
        }
    }

    &.no-ellipsis {
        ul > li > * span {
            bottom: 50px;
            top: auto !important;
        }
    } // END .no-ellipsis
} // END '.stepper'