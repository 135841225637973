// Cards list - base styles

.card-list {
    .card-list-item {
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        padding: 1.6rem 0rem;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-md-min) { flex-flow: nowrap; }

        &:not(:last-child) { margin-bottom: 1.2rem; } 

        &:hover { cursor: pointer; }

        > * {
            padding: 0.8rem 2.4rem;
        }

        &, 
        .action,
        .icon,
        .description {
            align-items: center;
            display: flex;
        }

        .icon {
            text-align: center;
            justify-content: center;
             
            > * {
                min-width: 3.6rem;
            }

            > a { 
                display: block; 
                padding: 0.6rem 0.2rem;

                &:hover {
                    background-color: var(--module-container-hover-bg-color);
                }
            }

            .vismaicon + * { 
                margin-top: 0.3rem; 
                display: block;
            }

        }

        .action {
            text-align: center;
        }

        .btn { 
            margin-bottom: 0; 

            @media (min-width: $screen-md-min) { min-width: 120px; } 
        }

        .vismaicon { 
            top: auto; 
            margin-right: 1.2rem;
            display: block;
        }

        .description {
            flex-grow: 1;
        }

        .divider {
            border-width: 0 1px;
            border-style: solid;
            border-color: var(--module-container-divider);

            .vismaicon { margin: 0; }

            + .divider { border-left-width: 0; }

            &:first-child { border-left-width: 0; }

            &:last-child { border-left-width: 0; } 
        }

   
    }
}